/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import ArraySort from 'array-sort';
import moment from 'moment';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import * as leagueActions from '../../../reducers/leaguesReducer';
import * as facilitiesActions from '../../../reducers/facilitiesReducer';
import '../../../styles/payments.css';
import Back from '../../../components/Back';
import ActionBar from '../../../components/ActionBar';
import TeamTable from './TeamTable';
import GenerateFixtures from './GenerateFixtures';
import FixtureList from './FixtureList';
import ToggleButtons from '../../../components/ToggleButtons';
import LinkFacility from './LinkFacility';
import LeagueTable from './LeagueTable';
import InviteTeamsToLeague from './InviteTeamsToLeague';
import CreateAdhocFixture from './CreateAdhocFixture';
import EditLeagueDialog from './EditLeagueDialog';
import TeamWaitList from './TeamWaitList';
import LeaguesTable from '../LeaguesTable';
import LEAGUE_STATUS from '../leagueStatusEnum';
import { useOrigin } from '../../../hooks';
import { Markdown } from '../../../helpers/Markdown';

const reformatFixtures = (fixtures = [], leagueId = null) => {
  const restructuredFixtures = [];
  let newFixtures = fixtures.map((fixture) => {
    if (!restructuredFixtures.some((e) => e.name === fixture.round)) {
      const allocation = fixture.allocations.filter((allocation) => allocation.status === 'ACTIVE')[0];
      restructuredFixtures.push({
        name: fixture.round,
        value: [{
          id: fixture.id,
          teamA: fixture.teamA,
          teamB: fixture.teamB,
          status: fixture.status,
          startTime: allocation?.startTime,
          endTime: allocation?.endTime,
          facilityName: allocation?.facility?.name,
          teamAScore: fixture.teamAScore,
          teamBScore: fixture.teamBScore,
          createdAt: fixture.createdAt,
          user_fixtures: fixture.user_fixtures,
          fixtureAgents: fixture.fixture_agents,
          leagueId,
          allocation,
        }],
      });
    } else {
      const allocation = fixture.allocations.filter((allocation) => allocation.status === 'ACTIVE')[0];
      const restructuredFixture = restructuredFixtures.find((e) => e.name === fixture.round);

      restructuredFixture.value.push({
        id: fixture.id,
        teamA: fixture.teamA,
        teamB: fixture.teamB,
        status: fixture.status,
        startTime: allocation?.startTime,
        endTime: allocation?.endTime,
        facilityName: allocation?.facility?.name,
        teamAScore: fixture.teamAScore,
        teamBScore: fixture.teamBScore,
        createdAt: fixture.createdAt,
        user_fixtures: fixture.user_fixtures,
        fixtureAgents: fixture.fixture_agents,
        leagueId,
        allocation,
      });
    }
    return restructuredFixtures;
  });
  newFixtures = restructuredFixtures;
  restructuredFixtures.forEach((round) => { round.value = ArraySort(round.value, ['teamA.name']); });
  newFixtures = ArraySort(restructuredFixtures, ['name', 'value.teamA.name']);
  return newFixtures;
};

export const leagueViewButtonsData = [
  {
    buttonTitle: 'Fixtures',
    buttonValue: 'FIXTURES',
  },
  {
    buttonTitle: 'Table',
    buttonValue: 'TABLE',
  },
  {
    buttonTitle: 'Confirmed',
    buttonValue: 'TEAMS',
  },
  {
    buttonTitle: 'Waiting',
    buttonValue: 'WAITING',
  },
  {
    buttonTitle: 'Invited',
    buttonValue: 'INVITED',
  },
];

const LeagueView = ({
  getLeague, match, requestFacilitiesRetrieval, updateScore, saveResult, redirectToLeagueMedia,
}) => {
  const [leagueView, setLeagueView] = useState('FIXTURES');
  const league = useSelector((state) => state.leagues.league);
  const teamWaitList = league.teams?.filter((team) => team.league_team?.status === 'WAITING');
  const teamInvitedList = league.teams?.filter((team) => team.league_team?.status === 'INVITED');
  const confirmedTeams = league.teams?.filter((team) => team.league_team?.status === 'CONFIRMED');
  const { fixtures, accessRestrictions } = league;
  const { divisions } = league;
  const {
    startTime, endTime, weekday, validTo, validFrom,
  } = accessRestrictions?.[0]
    ? accessRestrictions[0]
    : { startTime: null, endTime: null };
  const formattedFixtures = reformatFixtures(fixtures, league?.id);
  const totalRounds = fixtures ? Math.max(...fixtures.map((o) => o.round)) : 0;
  const formattedWeekday = weekday ? moment().day(weekday).format('dddd') : null;
  const formattedValidTo = validTo ? moment(validTo).format('DD MMM YYYY') : null;
  const formattedValidFrom = validFrom ? moment(validFrom).format('DD MMM YYYY') : null;
  const leagueInfo = useSelector((state) => state.leagues.league);
  const maxTeams = leagueInfo.maxTeams ? leagueInfo.maxTeams : leagueInfo.parentLeague?.maxTeams;
  const currentTeams = confirmedTeams?.length || 0;
  const { status } = league;
  const leagueStatus = LEAGUE_STATUS[status];
  const code = useSelector((state) => state?.companies?.companyInfo?.code?.toLowerCase());

  const { bookerUrl } = useOrigin();
  const { id } = match.params;
  useEffect(() => {
    getLeague(id);
    requestFacilitiesRetrieval();
  }, []);

  if (divisions && divisions.length > 0) {
    return (
      <>
        <ActionBar>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Back />
            <ConditionallyVisible condition={league}>
              <EditLeagueDialog />
            </ConditionallyVisible>
          </div>
        </ActionBar>
        <LeaguesTable leagues={divisions} />
      </>
    );
  }

  return (
    <div style={{ backgroundColor: '#fafbff' }}>
      <ActionBar>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Back />
          <InviteTeamsToLeague league={league} />
          <ConditionallyVisible condition={fixtures && fixtures.length === 0
            && confirmedTeams && confirmedTeams.length === maxTeams}
          >
            <GenerateFixtures teams={confirmedTeams} leagueId={league.id} />
          </ConditionallyVisible>
          <ConditionallyVisible condition={
            fixtures && fixtures.length > 0
            && league.accessRestrictions.filter((x) => x.status !== 'INACTIVE').length <= 0
            }
          >
            <LinkFacility />
          </ConditionallyVisible>
          <ConditionallyVisible condition={fixtures && fixtures.length > 0}>
            <CreateAdhocFixture
              teams={league.teams}
              totalRounds={totalRounds || 0}
              leagueId={league.id}
            />
          </ConditionallyVisible>

          <ConditionallyVisible condition={league}>
            <EditLeagueDialog />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => redirectToLeagueMedia(league.id)}
              style={{ marginLeft: '10px' }}
            >
              Add League Media
            </Button>
          </ConditionallyVisible>

          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <a href={`${bookerUrl}/league/${id}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="secondary">
                View League
              </Button>
            </a>
          </div>
        </div>

      </ActionBar>

      <div style={{
        marginTop: '-20px', paddingTop: '20px', height: '95.5vh', marginLeft: '20px', marginRight: '40px',
      }}
      >
        <div style={{
          borderRadius: '10px',
          border: '1px solid #ccc',
          backgroundColor: '#ffffff',
          padding: '20px',
          marginBottom: 20,
        }}
        >
          <ConditionallyVisible condition={league.name}>
            <h1>{league.name}</h1>
          </ConditionallyVisible>
          <ConditionallyVisible condition={league.description || league.parentLeague?.description}>
            <Markdown>
              {league.description || league.parentLeague?.description}
            </Markdown>
          </ConditionallyVisible>
          <ConditionallyVisible condition={formattedWeekday}>
            <p>
              {`This league starts on ${formattedValidFrom} and takes place every ${formattedWeekday} until ${formattedValidTo}.`}
            </p>
          </ConditionallyVisible>
          <ConditionallyVisible condition={startTime}>
            <p>
              {`Start Time: ${startTime}`}
            </p>
          </ConditionallyVisible>
          <ConditionallyVisible condition={endTime}>
            <p>
              {`End Time: ${endTime}`}
            </p>
          </ConditionallyVisible>
          <ConditionallyVisible condition={leagueStatus}>
            <p>
              {`Status: ${leagueStatus}`}
            </p>
          </ConditionallyVisible>
        </div>
        <div>
          <ToggleButtons
            buttonsData={leagueViewButtonsData}
            changeOption={(option) => setLeagueView(option)}
            value={leagueView}
          />
          <ConditionallyVisible condition={leagueView === 'TEAMS'}>
            <TeamTable teams={league.teams} leagueId={league.id} companyId={league.companyId} />
          </ConditionallyVisible>

          <ConditionallyVisible condition={currentTeams !== maxTeams && leagueView === 'FIXTURES'}>
            <Alert style={{ margin: '1rem 0rem' }} severity="info">
              <b>You cannot generate fixtures until the league is full!</b>
              <br />
              <b>{`Current Signups: ${currentTeams} / Required Signups: ${maxTeams}`}</b>
            </Alert>
          </ConditionallyVisible>

          <ConditionallyVisible condition={fixtures && fixtures.length > 0 && leagueView === 'FIXTURES'}>
            <FixtureList
              fixtures={formattedFixtures}
              updateScore={updateScore}
              saveResult={saveResult}
              teams={league.teams}
              generated
            />
          </ConditionallyVisible>

          <ConditionallyVisible condition={fixtures && fixtures.length > 0 && leagueView === 'TABLE'}>
            <LeagueTable
              standings={league.standings || []}
              adjustments={league.leagueTeamPointsAdjustments || []}
            />
          </ConditionallyVisible>

          <ConditionallyVisible condition={teamWaitList?.length > 0 && leagueView === 'WAITING'}>
            <TeamWaitList waitList={teamWaitList} league={league} />
          </ConditionallyVisible>
          <ConditionallyVisible condition={teamInvitedList?.length > 0 && leagueView === 'INVITED'}>
            <TeamWaitList waitList={teamInvitedList} league={league} />
          </ConditionallyVisible>

        </div>
      </div>
    </div>
  );
};

LeagueView.propTypes = {
  getLeague: PropTypes.func.isRequired,
  requestFacilitiesRetrieval: PropTypes.func.isRequired,
  updateScore: PropTypes.func.isRequired,
  saveResult: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  redirectToLeagueMedia: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getLeague: (id) => dispatch(leagueActions.getLeague(id)),
  updateScore: (score, team, fixtureId) => dispatch(
    leagueActions.updateScore(score, team, fixtureId),
  ),
  saveResult: (fixtureId, fixtureCost) => dispatch(
    leagueActions.saveResult(fixtureId, fixtureCost),
  ),
  requestFacilitiesRetrieval: () => dispatch(facilitiesActions.requestFacilitiesRetrieval()),
  redirectToLeagueMedia: (leagueId) => dispatch(push(`/leagues/${leagueId}/media`)),
});

export default connect(null, mapDispatchToProps)(LeagueView);
