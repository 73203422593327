import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import SubscriptionCards from './components/SubscriptionCards';
import SubscriptionsTable from './components/SubscriptionsTable';
import '../../styles/payments.css';
import * as actions from '../../reducers/subscriptionsReducer';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import BlockBookingCreationDialog from './components/BlockBookingCreationDialog';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import ActionBar from '../../components/ActionBar';
import ToolTip from '../../components/Tooltip';

class Subscriptions extends React.Component {
  componentDidMount() {
    const {
      getSubscriptions, requestFacilitiesRetrieval,
    } = this.props;
    getSubscriptions();
    requestFacilitiesRetrieval();
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    const { props } = this;
    const { isLoading, subscriptionStatus } = props.subscriptions;
    let { subscriptions } = props.subscriptions;
    const { currencySym, updateSubscriptionStatus } = props;
    let total = 0;
    const totalSubscriptions = subscriptions.length;
    let totalSubscriptionsActive = 0;
    let totalSubscriptionsInactive = 0;
    let totalSubscriptionsOfflinePayment = 0;

    subscriptions = subscriptions.filter((subscription) => (
      subscription.expires === null || moment(subscription.expires).isAfter(moment()))
      || (subscription.status === 'ACTIVE' || subscription.status === 'PROCESSING'));

    subscriptions.forEach((subscription) => {
      if (subscription.type === 'PAYP') {
        totalSubscriptionsOfflinePayment += 1;
        total += subscription.amount / 100;
      } else if (subscription.status === 'ACTIVE') {
        total += subscription.amount / 100;
        totalSubscriptionsActive += 1;
      } else if (subscription.status === 'INACTIVE') {
        totalSubscriptionsInactive += 1;
      }
    });
    return (
      <AlternativelyVisible condition={isLoading}>
        <LoadingSkeletonDataTable />
        <div style={{ background: '#fafbff', padding: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ActionBar>
              <BlockBookingCreationDialog disabled={isLoading} />
            </ActionBar>
            <ToolTip contentfulName="subscriptionsToolTip" style={{}} />
          </div>

          <>
            <SubscriptionCards
              totalSubscriptions={totalSubscriptions}
              total={total}
              totalSubscriptionsActive={totalSubscriptionsActive}
              totalSubscriptionsInactive={totalSubscriptionsInactive}
              totalSubscriptionsOfflinePayment={totalSubscriptionsOfflinePayment}
              currencySym={currencySym}
            />
            <SubscriptionsTable
              subscriptions={subscriptions}
              subscriptionStatus={subscriptionStatus}
              updateSubscriptionStatus={updateSubscriptionStatus}
            />
          </>
        </div>
      </AlternativelyVisible>
    );
  }
}

Subscriptions.propTypes = {
  subscriptions: PropTypes.shape().isRequired,
  currencySym: PropTypes.string,
  subscriptionStatus: PropTypes.string,
  getSubscriptions: PropTypes.func.isRequired,
  updateSubscriptionStatus: PropTypes.func.isRequired,
  requestFacilitiesRetrieval: PropTypes.func.isRequired,
};
Subscriptions.defaultProps = {
  currencySym: '£',
  subscriptionStatus: 'CURRENT',
};
function mapStateToProps(state) {
  const {
    subscriptions, facilities,
  } = state;
  const { currencySym } = state.companies.companyInfo;

  return {
    subscriptions, facilities, currencySym,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: () => dispatch(actions.getSubscriptions()),
  requestFacilitiesRetrieval: () => dispatch(facilitiesActions.requestFacilitiesRetrieval()),
  updateSubscriptionStatus: (status) => dispatch(actions.updateSubscriptionStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
