/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Typography, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BreakdownCard } from './BreakdownCard';

export const SubNonSubBreakdownCards = ({
  filteredOrderData, currencySym, showTotal,
}) => {
  const totalAmount = filteredOrderData.reduce(
    (acc, { total, refundTotal }) => acc + (total - (refundTotal || 0)) / 100, 0,
  );

  const {
    recurring, recurringCount, nonRecurring, nonRecurringCount,
  } = filteredOrderData.reduce(
    (acc, x) => x.orders.reduce((acc, order) => {
      if (order.tag === 'RECURRING') {
        acc.recurring += (order.total - (order.refundTotal || 0));
        acc.recurringCount += 1;
      } else if (order.tag === 'NON_RECURRING') {
        acc.nonRecurring += (order.total - (order.refundTotal || 0));
        acc.nonRecurringCount += 1;
      }
      return acc;
    }, acc),
    {
      recurring: 0, recurringCount: 0, nonRecurring: 0, nonRecurringCount: 0,
    },
  );

  return (
    <Accordion
      elevation={0}
      style={{
        backgroundColor: '#F5F5F5',
        border: '1px solid #ddd',
        borderRadius: '0 0 0.75rem 0.75rem',
        margin: '0 2rem',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography style={{ fontWeight: '600' }}>Recurring / Non-Recurring Revenue Breakdown</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
            gap: '1rem',
          }}
        >
          {showTotal && (
          <BreakdownCard
            key="total"
            title="Total Revenue"
            count={null}
            type="total"
            total={totalAmount.toFixed(2)}
            currencySym={currencySym}
            totalOrderAmount={totalAmount}
            componentType="total"
          />
          )}
          {/* Subscription Total */}
          <BreakdownCard
            title="Recurring Total"
            count={recurringCount}
            type="subscription"
            total={((recurring) / 100).toFixed(2)} // Convert pence to pounds
            currencySym={currencySym}
            totalOrderAmount={recurring} // Convert pence to pounds
            componentType="subNonSub"
          />

          {/* Non-Subscription Total */}
          <BreakdownCard
            title="Non-Recurring Total"
            count={nonRecurringCount}
            type="nonSubscription"
            total={((nonRecurring) / 100).toFixed(2)} // Convert pence to pounds
            currencySym={currencySym}
            totalOrderAmount={nonRecurring} // Convert pence to pounds
            componentType="subNonSub"
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
