import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import CloseDialogIcon from '@material-ui/icons/Close';

const RefundDialog = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  order,
  currencySym,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
  >
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <DialogTitle>Confirm Refund</DialogTitle>
      <DialogActions variant="none">
        <CloseDialogIcon onClick={onClose} />
      </DialogActions>
    </div>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to refund this payment?
      </DialogContentText>
      <div style={{ marginTop: '1rem' }}>
        <Typography variant="subtitle1">
          {`Refund amount: ${currencySym}${(order?.total / 100).toFixed(2)} (${currencySym}${order?.totalExTax?.toFixed(2)} + ${currencySym}${order?.tax?.toFixed(2)} tax)`}
        </Typography>
        {order?.paymentMethod === 'STRIPE' && (
          <Typography variant="subtitle1" style={{ marginTop: '0.5rem' }}>
            This will initiate a refund through Stripe.
          </Typography>
        )}
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={onClose}
        color="secondary"
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={onConfirm}
        color="primary"
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Confirm Refund'}
      </Button>
    </DialogActions>
  </Dialog>
);

RefundDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    total: PropTypes.number,
    totalExTax: PropTypes.number,
    tax: PropTypes.number,
    paymentMethod: PropTypes.string,
  }).isRequired,
  currencySym: PropTypes.string.isRequired,
};

export default RefundDialog;
