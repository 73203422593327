import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { createExclusionService } from '../../../services/subscriptionsServices';
import { getCalendarUsage } from '../../../reducers/calendarReducer';
import { useCompany, useToast } from '../../../hooks';
import { OverlayLoader } from '../../../components/OverlayLoader';
import { handleCalendarPaymentDialog } from '../../../reducers/reservationsReducer';

export const ExcludeCalendarSlot = ({ subscription = {} }) => {
  const company = useCompany();
  const toast = useToast();
  const dispatch = useDispatch();
  const { id: companyId } = company;
  const { accessRestriction, slot } = subscription;
  const { id: accessRestrictionId } = accessRestriction ?? {};
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const formattedSlot = moment(slot).format('YYYY/MM/DD');
  const slotText = moment(slot).format('MMMM Do YYYY @ h:mm a');

  const mutation = useMutation({
    mutationFn: async () => {
      const exclusions = [{ date: formattedSlot }];
      const res = await createExclusionService(companyId, accessRestrictionId, exclusions);

      if (res.status !== 200) {
        throw new Error('Error creating exclusion');
      }

      return res.data;
    },
    onSuccess: () => {
      dispatch(getCalendarUsage());
      toast.trigger({
        type: 'success',
        message: `Exclusion added for: ${slotText}`,
      });
      setIsDialogOpen(false);
      dispatch(handleCalendarPaymentDialog(false));
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error creating exclusion',
      });
    },
  });

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  if (!subscription) {
    return null;
  }

  return (
    <>
      <Button onClick={handleOpen} variant="contained" color="secondary" disabled={mutation.isSuccess}>
        {mutation.isSuccess ? 'Slot Removed' : 'Remove slot'}
      </Button>

      <Dialog open={isDialogOpen} onClose={handleClose}>
        {mutation.isLoading && (
          <OverlayLoader />
        )}

        <DialogTitle>
          <Typography variant="h6">Confirm Exclusion</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to add an exclusion to the following date?
            <ul>
              <li style={{ fontWeight: '600' }}>{slotText}</li>
            </ul>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose} disabled={mutation.isLoading}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => mutation.mutate()} disabled={mutation.isLoading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ExcludeCalendarSlot.propTypes = {
  subscription: PropTypes.shape().isRequired,
};
