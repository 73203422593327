/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as facilitiesActions from '../../reducers/facilitiesReducer';
import * as calendarActions from '../../reducers/calendarReducer';
import OrderTable from './OrderTable';
import '../../styles/payments.css';
import {
  PaymentsActionBar,
  PermanentPaymentBreakdown,
  ProductBreakdownCards,
  productBreakdownConstants,
  SubNonSubBreakdownCards,
} from './Components';

const Payments = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.orders);
  const { stripeId, currencySym } = useSelector((state) => state.companies.companyInfo);
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [productCardData, setProductCardData] = useState([]);
  const [subNonSubData, setSubNonSubData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    dispatch(calendarActions.getCompanySites());
    dispatch(facilitiesActions.requestFacilitiesRetrieval());
  }, [dispatch]);

  // Process orders into product card data
  useEffect(() => {
    if (orders && orders.length > 0) {
      // Split orders out into checkout orders, non-checkout orders and refund orders
      setFilteredOrders(orders); // Showing all orders in table to start
      const {
        REFUND_ORDERS,
        ORDERS,
      } = orders.reduce((acc, x) => {
        if (x.status === 'REFUNDED') {
          acc.REFUND_ORDERS.push(x);
          return acc;
        }

        acc.ORDERS.push(x);
        return acc;
      }, { REFUND_ORDERS: [], ORDERS: [] });

      // Every order gets updated with corresponding data
      const MANAGED_ORDERS = ORDERS.map((order) => {
        const mutated = {
          ...order,
          orders: [order],
          facility: {
            orders: [],
            refundedOrders: [],
            total: 0,
            netTotal: 0,
            refundedTotal: 0,
          },
          membership: {
            orders: [],
            refundedOrders: [],
            total: 0,
            netTotal: 0,
            refundedTotal: 0,
          },
          event: {
            orders: [],
            refundedOrders: [],
            total: 0,
            netTotal: 0,
            refundedTotal: 0,
          },
          invoice: {
            orders: [],
            refundedOrders: [],
            total: 0,
            netTotal: 0,
            refundedTotal: 0,
          },
          serviceFee: {
            orders: [],
            refundedOrders: [],
            total: 0,
            netTotal: 0,
            refundedTotal: 0,
          },
          pos: {
            orders: [],
            refundedOrders: [],
            total: 0,
            netTotal: 0,
            refundedTotal: 0,
          },
          league: {
            orders: [],
            refundedOrders: [],
            total: 0,
            netTotal: 0,
            refundedTotal: 0,
          },
        };

        // Get the refund values
        mutated.refunds = REFUND_ORDERS.filter((refund) => refund.refundedFromOrderId === order.id);
        mutated.refundTotal = mutated.refunds.reduce((acc, refund) => acc + refund.total, 0);
        mutated.netTotal = mutated.total - mutated.refundTotal;

        // Assign each category it's categorised refunds
        if (mutated.refunds.length > 0) {
          // Map out checkout orders
          const orders = [
            ...mutated.refunds,
            ...mutated.refunds.flatMap((refund) => refund.checkoutOrders),
          ];

          if (mutated.refunds.some((x) => x.checkoutOrders.length > 0)) {
            mutated.refunds = mutated.refunds.flatMap((refund) => refund.checkoutOrders);
          }

          // Assign each individual refund to the correct location
          mutated.facility.refundedOrders = orders.filter((refund) => (
            refund.reservations.some(
              (reservation) => (
                reservation.allocations.some((allocation) => allocation.type === 'FACILITY' || allocation.type === 'AMENITY')
              ),
            )
            || refund.subscription_orders?.length > 0
            || refund.subscriptions?.some((subscription) => subscription.productType === 'FACILITY')
          ));
          mutated.league.refundedOrders = orders.filter((refund) => refund.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'LEAGUE')));
          mutated.event.refundedOrders = orders.filter((refund) => refund.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'EVENT')));
          mutated.invoice.refundedOrders = orders.filter((refund) => refund.reservations.some((reservation) => reservation.type === 'INVOICE_ITEM'));
          mutated.serviceFee.refundedOrders = orders.filter((refund) => refund.reservations.some((reservation) => reservation.type === 'SERVICE_FEE'));
          mutated.pos.refundedOrders = orders.filter((refund) => refund.reservations.some((reservation) => reservation.type === 'POS'));

          // Calculate the refund totals
          mutated.facility.refundedTotal = mutated.facility.refundedOrders.reduce((acc, refund) => acc + refund.total, 0);
          mutated.league.refundedTotal = mutated.league.refundedOrders.reduce((acc, refund) => acc + refund.total, 0);
          mutated.event.refundedTotal = mutated.event.refundedOrders.reduce((acc, refund) => acc + refund.total, 0);
          mutated.invoice.refundedTotal = mutated.invoice.refundedOrders.reduce((acc, refund) => acc + refund.total, 0);
          mutated.serviceFee.refundedTotal = mutated.serviceFee.refundedOrders.reduce((acc, refund) => acc + refund.total, 0);
          mutated.pos.refundedTotal = mutated.pos.refundedOrders.reduce((acc, refund) => acc + refund.total, 0);
        }

        // Checkout orders don't have reservations, so we can get the reservations from the child orders
        if (mutated.checkoutOrders?.length > 0) {
          const { checkoutOrders } = mutated;

          // Checkout orders do not have reservations, so we can get those from the child orders
          mutated.reservations = checkoutOrders.flatMap((checkoutOrder) => checkoutOrder.reservations);
          mutated.subscriptions = checkoutOrders.flatMap((checkoutOrder) => checkoutOrder.subscription_orders);
          mutated.orders = checkoutOrders;
        }
        // Filter out so we only have created and refunded orders
        mutated.orders = mutated.orders.filter((x) => x.status === 'CREATED' || x.status === 'REFUNDED');

        if ((mutated.subscriptions.length > 0 && mutated.reservations.length > 0) && mutated.orders.some((x) => x.checkoutOrderId)) {
          const { orders } = mutated;

          // Can never have a membership and reservation together so they will never enter here, so we can assume
          // that all subscriptions are facilities
          mutated.facility.orders = [
            ...orders.filter((x) => x.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'FACILITY'))).map((x) => ({
              ...x,
              refundTotal: mutated.facility.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
              tag: 'NON_RECURRING',
            })),
            ...orders.filter((x) => x.subscription_orders.length > 0).map((x) => ({
              ...x,
              refundTotal: mutated.facility.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
              tag: 'RECURRING',
            })),
          ];
          mutated.event.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'EVENT')))
            .map((x) => ({
              ...x,
              refundTotal: mutated.event.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
              tag: 'NON_RECURRING',
            }));
          mutated.invoice.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.type === 'INVOICE_ITEM'))
            .map((x) => ({
              ...x,
              refundTotal: mutated.invoice.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
              tag: 'NON_RECURRING',
            }));
          mutated.serviceFee.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.type === 'SERVICE_FEE'))
            .map((x) => ({
              ...x,
              refundTotal: mutated.serviceFee.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
              tag: 'NON_RECURRING',
            }));
          mutated.pos.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.type === 'POS'))
            .map((x) => ({
              ...x,
              refundTotal: mutated.pos.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
              tag: 'NON_RECURRING',
            }));
          mutated.league.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'LEAGUE')))
            .map((x) => ({
              ...x,
              refundTotal: mutated.league.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
              tag: 'NON_RECURRING',
            }));
        } else {
          // Manage anything related to subscriptions in here
          if (mutated.subscriptions?.length > 0) {
            const { orders } = mutated;

            mutated.facility.orders = orders.filter((x) => x.subscriptions.some((subscription) => subscription.productType === 'FACILITY'))
              .map((x) => ({
                ...x,
                refundTotal: mutated.facility.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
                tag: 'RECURRING',
              }));
            mutated.membership.orders = orders.filter((x) => x.subscriptions.some((subscription) => subscription.membershipId))
              .map((x) => ({
                ...x,
                refundTotal: mutated.membership.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
                tag: 'RECURRING',
              }));
          }

          // Manage anything related to reservations in here
          if (mutated.reservations?.length > 0) {
            const { orders } = mutated;

            mutated.facility.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'FACILITY')))
              .map((x) => ({
                ...x,
                refundTotal: mutated.facility.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
                tag: 'NON_RECURRING',
              }));
            mutated.event.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'EVENT')))
              .map((x) => ({
                ...x,
                refundTotal: mutated.event.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
                tag: 'NON_RECURRING',
              }));
            mutated.invoice.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.type === 'INVOICE_ITEM'))
              .map((x) => ({
                ...x,
                refundTotal: mutated.invoice.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
                tag: 'NON_RECURRING',
              }));
            mutated.serviceFee.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.type === 'SERVICE_FEE'))
              .map((x) => ({
                ...x,
                refundTotal: mutated.serviceFee.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
                tag: 'NON_RECURRING',
              }));
            mutated.pos.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.type === 'POS'))
              .map((x) => ({
                ...x,
                refundTotal: mutated.pos.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
                tag: 'NON_RECURRING',
              }));
            mutated.league.orders = orders.filter((order) => order.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'LEAGUE')))
              .map((x) => ({
                ...x,
                refundTotal: mutated.league.refundedOrders.filter((refund) => refund.refundedFromOrderId === x.id).reduce((acc, refund) => acc + refund.total, 0),
                tag: 'NON_RECURRING',
              }));
          }
        }

        // Calculate the totals for each category
        mutated.facility.total = mutated.facility.orders.reduce((acc, facility) => acc + facility.total, 0);
        mutated.event.total = mutated.event.orders.reduce((acc, event) => acc + event.total, 0);
        mutated.invoice.total = mutated.invoice.orders.reduce((acc, invoice) => acc + invoice.total, 0);
        mutated.serviceFee.total = mutated.serviceFee.orders.reduce((acc, serviceFee) => acc + serviceFee.total, 0);
        mutated.pos.total = mutated.pos.orders.reduce((acc, pos) => acc + pos.total, 0);
        mutated.league.total = mutated.league.orders.reduce((acc, league) => acc + league.total, 0);

        // Calculate the net totals for each category
        mutated.facility.netTotal = mutated.facility.total - mutated.facility.refundedTotal;
        mutated.event.netTotal = mutated.event.total - mutated.event.refundedTotal;
        mutated.invoice.netTotal = mutated.invoice.total - mutated.invoice.refundedTotal;
        mutated.serviceFee.netTotal = mutated.serviceFee.total - mutated.serviceFee.refundedTotal;
        mutated.pos.netTotal = mutated.pos.total - mutated.pos.refundedTotal;
        mutated.league.netTotal = mutated.league.total - mutated.league.refundedTotal;

        return mutated;
      });

      let data = MANAGED_ORDERS.reduce((acc, x) => {
        acc.facility = {
          count: acc.facility.count + x.facility.orders.length + x.facility.refundedOrders.length,
          orders: acc.facility.orders.concat(x.facility.orders),
          refundTotal: acc.facility.refundTotal + x.facility.refundedTotal,
          title: 'Facility',
          total: acc.facility.total + x.facility.total,
          type: 'facility',
        };
        acc.event = {
          count: acc.event.count + x.event.orders.length + x.event.refundedOrders.length,
          orders: acc.event.orders.concat(x.event.orders),
          refundTotal: acc.event.refundTotal + x.event.refundedTotal,
          title: 'Event',
          total: acc.event.total + x.event.total,
          type: 'event',
        };
        acc.membership = {
          count: acc.membership.count + x.membership.orders.length + x.membership.refundedOrders.length,
          orders: acc.membership.orders.concat(x.membership.orders),
          refundTotal: acc.membership.refundTotal + x.membership.refundedTotal,
          title: 'Membership',
          total: acc.membership.total + x.membership.total,
          type: 'membership',
        };
        acc.invoice = {
          count: acc.invoice.count + x.invoice.orders.length + x.invoice.refundedOrders.length,
          orders: acc.invoice.orders.concat(x.invoice.orders),
          refundTotal: acc.invoice.refundTotal + x.invoice.refundedTotal,
          title: 'Invoice',
          total: acc.invoice.total + x.invoice.total,
          type: 'invoice',
        };
        acc.serviceFee = {
          count: acc.serviceFee.count + x.serviceFee.orders.length + x.serviceFee.refundedOrders.length,
          orders: acc.serviceFee.orders.concat(x.serviceFee.orders),
          refundTotal: acc.serviceFee.refundTotal + x.serviceFee.refundedTotal,
          title: 'Service Fee',
          total: acc.serviceFee.total + x.serviceFee.total,
          type: 'serviceFee',
        };
        acc.pos = {
          count: acc.pos.count + x.pos.orders.length + x.pos.refundedOrders.length,
          orders: acc.pos.orders.concat(x.pos.orders),
          refundTotal: acc.pos.refundTotal + x.pos.refundedTotal,
          title: 'POS',
          total: acc.pos.total + x.pos.total,
          type: 'pos',
        };
        acc.league = {
          count: acc.league.count + x.league.orders.length + x.league.refundedOrders.length,
          orders: acc.league.orders.concat(x.league.orders),
          refundTotal: acc.league.refundTotal + x.league.refundedTotal,
          title: 'League',
          total: acc.league.total + x.league.total,
          type: 'league',
        };
        return acc;
      }, {
        facility: {
          count: 0,
          orders: [],
          refundTotal: 0,
          title: 'Facility',
          total: 0,
          type: 'facility',
        },
        event: {
          count: 0,
          orders: [],
          refundTotal: 0,
          title: 'Event',
          total: 0,
          type: 'event',
        },
        membership: {
          count: 0,
          orders: [],
          refundTotal: 0,
          title: 'Membership',
          total: 0,
          type: 'membership',
        },
        invoice: {
          count: 0,
          orders: [],
          refundTotal: 0,
          title: 'Invoice',
          total: 0,
          type: 'invoice',
        },
        serviceFee: {
          count: 0,
          orders: [],
          refundTotal: 0,
          title: 'Service Fee',
          total: 0,
          type: 'serviceFee',
        },
        pos: {
          count: 0,
          orders: [],
          refundTotal: 0,
          title: 'POS',
          total: 0,
          type: 'pos',
        },
        league: {
          count: 0,
          orders: [],
          refundTotal: 0,
          title: 'League',
          total: 0,
          type: 'league',
        },
      });

      // get refunds that are not associated with any orders
      const flattenedRefunds = MANAGED_ORDERS.reduce((acc, x) => {
        acc.push(...x.refunds.flat());
        return acc;
      }, []);

      // filter out checkout order refunds that are already accounted for in flattened refunds
      const filteredRefunds = REFUND_ORDERS.filter((x) => !flattenedRefunds.some((y) => x.id === y.id || y.checkoutOrderId === x.id));
      const mergedRefunds = [...filteredRefunds, ...flattenedRefunds];

      // If there are any refunds that DO NOT it's original order then we add them to the designated card
      mergedRefunds.forEach((refund) => {
        const refunds = !refund.checkoutOrders || refund.checkoutOrders?.length === 0 ? [refund] : refund.checkoutOrders;
        refunds.forEach((r) => {
          const isInManagedOrders = MANAGED_ORDERS.some((order) => order.orders.some((o) => o.id === r.refundedFromOrderId));
          if (!isInManagedOrders) {
            // Assign each individual refund to the correct location
            const facility = r.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'FACILITY' || allocation.type === 'AMENITY'));
            const event = r.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'EVENT'));
            const invoice = r.reservations.some((reservation) => reservation.type === 'INVOICE_ITEM');
            const serviceFee = r.reservations.some((reservation) => reservation.type === 'SERVICE_FEE');
            const pos = r.reservations.some((reservation) => reservation.type === 'POS');
            const league = r.reservations.some((reservation) => reservation.allocations.some((allocation) => allocation.type === 'LEAGUE'));

            // TODO: Tidy this up
            // eslint-disable-next-line no-nested-ternary
            const field = facility ? 'facility' : event ? 'event' : invoice ? 'invoice' : serviceFee ? 'serviceFee' : pos ? 'pos' : league ? 'league' : null;

            // TODO: Added this to prevent S5s crashing
            if (!field) {
              return;
            }

            data[field].refundTotal += r.total;
            data[field].count += 1;
            data[field].orders.push({
              ...r,
              total: r.total * -1,
              tag: 'NON_RECURRING',
            });
          }
        });
      });

      data = Object.values(data);

      const productCardData = data.filter((card) => card.count > 0 && card.type !== 'subscription' && card.type !== 'partial');
      setProductCardData(productCardData);

      const allFilteredData = data.filter((card) => card.count > 0);
      setSubNonSubData(allFilteredData);

      const options = productCardData.map((card) => ({
        label: card.title,
        value: card.type,
      }));
      setSelectedOptions(options);
    }
  }, [orders]);

  const handleProductBreakdownSelectionChange = useCallback(
    (selected) => {
      const selectedTitles = selected.map((option) => option.label);
      const selectedCardData = productCardData.filter((card) => selectedTitles.includes(card.title));
      // filter selected card data to be unique orders
      const updatedOrders = selectedCardData.flatMap((card) => card.orders);
      const uniqueOrders = Array.from(new Set(updatedOrders.map((order) => order.id)))
        .map((id) => updatedOrders.find((order) => order.id === id));

      setFilteredOrders(uniqueOrders);
      setSelectedOptions(selected);
    },
    [productCardData],
  );

  // Only show select options that have data
  const availableOptions = productCardData.map((card) => ({
    label: card.title,
    value: card.type,
  }));

  // Custom styles for react-select (to colourise selected options)
  const selectStyles = {
    multiValue: (provided, state) => {
      const color = productBreakdownConstants[state.data.value]?.color || provided.backgroundColor;
      return {
        ...provided,
        backgroundColor: color,
      };
    },
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
      fontWeight: '600',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: state.data.color,
        color: 'black',
        cursor: 'pointer',
      },
    }),
  };

  return (
    <>
      <PaymentsActionBar />
      {orders.length === 0 ? (
        <div className="site-selection">There is no data available for this date and site.</div>
      ) : (
        <>
          <PermanentPaymentBreakdown orders={orders.filter((x) => x.status === 'REFUNDED' || x.status === 'CREATED')} />

          <Accordion
            elevation={0}
            style={{
              backgroundColor: '#F5F5F5',
              border: '1px solid #ddd',
              margin: '0 2rem',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={{ fontWeight: '600' }}>Product Revenue Breakdown</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Select
                options={availableOptions}
                onChange={handleProductBreakdownSelectionChange}
                isMulti
                value={selectedOptions}
                placeholder="Select filters"
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                styles={selectStyles}
              />

              <ProductBreakdownCards
                productCardData={productCardData}
                selectedOptions={selectedOptions}
                currencySym={currencySym}
                showTotal
              />
            </AccordionDetails>
          </Accordion>

          <SubNonSubBreakdownCards
            filteredOrderData={subNonSubData}
            selectedOptions={selectedOptions}
            currencySym={currencySym}
            showTotal
          />

          <OrderTable
            orders={filteredOrders}
            stripeId={stripeId}
            currencySym={currencySym}
          />
        </>
      )}
    </>
  );
};

export default Payments;
