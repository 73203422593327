/* eslint-disable react/prop-types */
/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import ReactMapboxGl, { Layer, Marker, Popup } from 'react-mapbox-gl';
import { getAllFacilitiesService } from '../../services/facilitiesServices';
import { usePitchbookingUser } from '../../hooks';

const MapBox = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoicGl0Y2hib29raW5nLWRldiIsImEiOiJjanM5aGRpazExaDJiNDRvM251YzRuaTBpIn0.kgYWBsipnL68dnhceLnWzw',
});

export const Map = ({ history }) => {
  const { isPitchbookingManager } = usePitchbookingUser();
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [mapCenter, setMapCenter] = useState([-5.930027, 54.596369]);
  const [mapZoom, setMapZoom] = useState([5]);

  const { data, isError } = useQuery({
    queryKey: ['facilities'],
    queryFn: async () => {
      const { data } = await getAllFacilitiesService();
      return data;
    },
    enabled: isPitchbookingManager,
  });

  const handleSelecton = (facility) => {
    setSelectedFacility(facility);
    setMapCenter([
      facility.location.coordinates[0],
      facility.location.coordinates[1],
    ]);
  };

  if (!isPitchbookingManager) history.replace('/');
  if (isError) return 'Error!';

  return (
    <MapBox
      style="mapbox://styles/pitchbooking-dev/cjs9hx23l59x81fl3jertz74l"
      containerStyle={{
        height: '95vh',
        width: '100%',
        maxWidth: '100rem',
      }}
      center={mapCenter}
      zoom={mapZoom}
      onZoomEnd={(map) => setMapZoom([map.getZoom()])}
    >
      <Layer
        type="symbol"
        id="marker"
        layout={{
          'icon-image': 'location-pointer-2',
          'icon-size': 0.075,
          'icon-anchor': 'bottom',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
        }}
      >
        {data?.map((facility) => (
          <Marker
            key={facility.id}
            coordinates={[
              facility.location.coordinates[0],
              facility.location.coordinates[1],
            ]}
            onClick={() => handleSelecton(facility)}
          />
        ))}
      </Layer>
      {
        selectedFacility && (
          <Popup
            coordinates={[
              selectedFacility.location.coordinates[0],
              selectedFacility.location.coordinates[1],
            ]}
            offset={{ bottom: [0, -30] }}
            onClose={() => setSelectedFacility(null)}
            style={{
              padding: '0.25rem',
            }}
          >
            <p style={{ fontWeight: 600, fontSize: '14px', margin: '0 0.25rem' }}>{selectedFacility.name}</p>
            <a href={`https://pitchbooking.com/partners/${selectedFacility?.company?.code}`}>{selectedFacility?.company?.name}</a>
          </Popup>
        )
      }
    </MapBox>
  );
};
