import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import * as helper from '../../helpers/index';
import PriceModifierTags from './PriceModifierTags';
import * as pricingActions from '../../reducers/pricingReducer';
import * as companyActions from '../../reducers/companiesReducer';
import PricingGroupDialog from './PricingGroupDialog';
import ActionBar from '../../components/ActionBar';
import DuplicatePriceGroupDialog from './DuplicatePriceGroupDialog';
import '../../styles/pricing.css';
import ToolTip from '../../components/Tooltip';
import FacilityChips from '../../components/FacilityChips';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    width: '90%',
    margin: 'auto',
    backgroundColor: '#f8f9fb',
    marginBottom: theme.spacing(2),
    border: 'none',
    boxShadow: 'none',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const generateCombinedRuleSearchString = (pms) => {
  const combinedString = [];
  pms.map((pm) => combinedString.push(helper.generatePriceModifierString(pm).toLowerCase()));
  return combinedString.join(' ');
};

const generatePriceModifierValidityString = (pp) => {
  const validity = {
    validFrom: pp.startDate,
    validTo: pp.endDate,
  };
  return helper.generateValidityText(validity);
};

const generateMembershipString = (pp) => (pp.membership ? pp.membership.name.toLowerCase() : '');

const facilityTag = ((row) => (
  <>
    {row.resourceType !== 'Facility' ? `${row.resourceType}: ` : ''}
    {row.name}
    {row.resourceType === 'Event' ? ` (${moment(row.startDate).format('DD-MMM')} - ${moment(row.endDate).format('DD-MMM')})` : ''}
    <div style={{ color: '#a2a2a2' }}>
      {row.pricePackageId}
    </div>
  </>
));

const membershipTag = (row) => (
  <>
    {row.membership ? (
      <div className="membershipsTag">
        {row.membership.name}
      </div>
    ) : <></>}
  </>
);

function PricePackagesTable({
  pricePackages,
  pricingErrorMessages,
  companyMemberships,
  pricingEnabled,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { currentUser } = useSelector((state) => state.users);

  const enablePriceGroupEdit = (priceGroup) => dispatch(
    pricingActions.enablePriceGroupEdit(priceGroup),
  );
  const getMemberships = (priceGroup) => dispatch(
    companyActions.requestCompanyMembershipRetrieval(priceGroup),
  );
  const requestPriceGroupDelete = (priceGroup) => dispatch(
    pricingActions.requestPriceGroupDelete(priceGroup),
  );

  let siteFlag = false;
  let membershipFlag = false;

  const priceGroupEditEnabled = useSelector((state) => state.pricing.priceGroup);

  pricePackages.forEach((pricePackage) => {
    if (pricePackage.siteId) {
      siteFlag = true;
    }
    if (pricePackage.membership) {
      membershipFlag = true;
    }
  });

  const handleEdit = (priceGroup) => {
    dispatch(pricingActions.toggledEditingPriceGroup());
    getMemberships();
    enablePriceGroupEdit({ ...priceGroup });
    dispatch(pricingActions.togglePriceGroupDialog());
  };

  const handleDelete = (priceGroup) => {
    requestPriceGroupDelete(priceGroup);
  };

  useEffect(() => {
    if (priceGroupEditEnabled.priceGroupId) {
      const pricePackage = pricePackages.find(
        (pp) => pp.priceGroupId === priceGroupEditEnabled.priceGroupId,
      );
      enablePriceGroupEdit(pricePackage);
    }
  }, [pricePackages]);

  const columns = [
    {
      title: 'Facility Name',
      render: (row) => (
        <FacilityChips facilities={row.facilities} />
      ),
      customFilterAndSearch: (term, row) => {
        const facilityString = row.facilities.map((f) => f.name).join('-').toLowerCase();
        return facilityString.includes(term.toLowerCase());
      },
    },
    {
      title: 'Site Name',
      field: 'site.name',
      hidden: !siteFlag,
    },
    {
      title: 'Validity',
      render: (pp) => generatePriceModifierValidityString(pp),
      customFilterAndSearch: (term, pp) => {
        const validityString = generatePriceModifierValidityString(pp).toLowerCase();
        return validityString.includes(term.toLowerCase());
      },
      customSort: (a, b) => generatePriceModifierValidityString(a).localeCompare(
        generatePriceModifierValidityString(b),
      ),
    },
    {
      title: 'Membership',
      hidden: !membershipFlag,
      render: (row) => membershipTag(row),
      customFilterAndSearch: (term, pp) => generateMembershipString(pp).includes(
        term.toLowerCase(),
      ),
      customSort: (a, b) => generateMembershipString(a).localeCompare(
        generateMembershipString(b),
      ),
    },
    {
      title: 'Rules',
      render: (row) => (
        <>
          <PriceModifierTags
            priceGroup={row}
            showEditButton={false}
          />
        </>
      ),
      customFilterAndSearch: (term, row) => {
        const str = generateCombinedRuleSearchString(row.priceModifiers);
        return str.includes(term.toLowerCase());
      },
    }];

  if (pricingEnabled) {
    columns.push({
      title: 'Actions',
      render: (row) => (
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          <Button color="secondary" aria-label="edit" onClick={() => handleEdit(row)}>
            <EditIcon />
          </Button>
          <DuplicatePriceGroupDialog
            row={row}
            membershipTag={membershipTag}
            facilityTag={facilityTag}
            companyMemberships={companyMemberships}
          />
          {row.priceModifiers.some((pm) => pm.modifierType === 'DEFAULT') ? <></> : (
            <Button
              style={{ color: '#ac372f' }}
              aria-label="delete"
              onClick={() => handleDelete(row)}
            >
              <DeleteForeverIcon />
            </Button>
          )}
        </div>

      ),
    });
  }

  return (
    <div className="table-section">
      <div className={classes.root}>
        <ConditionallyVisible condition={pricingEnabled}>
          <div display="flex">
            <ActionBar>
              <PricingGroupDialog />
            </ActionBar>
            <div>
              {pricingErrorMessages.map((msg) => (<div style={{ paddingBottom: '10px' }}><Alert severity="error">{msg}</Alert></div>))}
            </div>
          </div>
        </ConditionallyVisible>
        <ToolTip contentfulName="pricingTabToolTip" />
        <ConditionallyVisible condition={pricingEnabled}>
          <Paper
            style={{
              margin: '10px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%',
            }}
            component={Paper}
          >
            <MaterialTable
              className={classes.table}
              data={pricePackages}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              title="Pitchbooking Access Restrictions"
              icons={{
                NextPage: () => <ChevronRight />,
                PreviousPage: () => <ChevronLeft />,
                Search: () => <Search />,
                Export: () => <SaveAlt />,
                ResetSearch: () => <Clear />,
                SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
                Filter: () => <div />,
              }}
              columns={columns}
              options={{
                search: true,
                filtering: true,
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                pageSize: 50,
                pageSizeOptions: [10, 25, 50, 100],
                showFirstLastPageButtons: false,
                showTitle: false,
                headerStyle: {
                  backgroundColor: '#efefef',
                  fontWeight: 'bold',
                },
              }}
            />
          </Paper>
        </ConditionallyVisible>
      </div>
    </div>
  );
}

PricePackagesTable.propTypes = {
  companyMemberships: PropTypes.shape().isRequired,
  pricePackages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pricingErrorMessages: PropTypes.arrayOf(PropTypes.string),
  pricingEnabled: PropTypes.bool,
};

PricePackagesTable.defaultProps = {
  pricingErrorMessages: [],
  pricingEnabled: false,
};

export default PricePackagesTable;
