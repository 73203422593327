export const RESERVATIONS_CREATION_REQUESTED = 'RESERVATIONS_CREATION_REQUESTED';
export const RESERVATIONS_CREATION_SUCCEEDED = 'RESERVATIONS_CREATION_SUCCEEDED';
export const RESERVATIONS_CREATION_FAILED = 'RESERVATIONS_CREATION_FAILED';
export const RESERVATIONS_CREATION_RESET = 'RESERVATIONS_CREATION_RESET';
export const RESERVATIONS_CREATION_TYPE_UPDATED = 'RESERVATIONS_CREATION_TYPE_UPDATED';
export const RESERVATIONS_CREATION_POPUP_TOGGLED = 'RESERVATIONS_CREATION_POPUP_TOGGLED';

export const UPDATE_RESERVATION = 'UPDATE_RESERVATION';
export const CANCEL_RESERVATIONS = 'CANCEL_RESERVATIONS';
export const CANCEL_RESERVATIONS_FAILURE = 'CANCEL_RESERVATIONS_FAILURE';
export const CANCEL_RESERVATIONS_SUCCESS = 'CANCEL_RESERVATIONS_SUCCESS';
export const RESET_CANCELLED_RESERVATIONS_STORE = 'RESET_CANCELLED_RESERVATIONS_STORE';
export const UPDATE_CANCEL_RESERVATIONS_STORE = 'UPDATE_CANCEL_RESERVATIONS_STORE';
export const UPDATE_RECORD_PAYMENT_STORE = 'UPDATE_RECORD_PAYMENT_STORE';
export const RECORD_MULTIPLE_RESERVATION_PAYMENT = 'RECORD_MULTIPLE_RESERVATION_PAYMENT';
export const RECORD_MULTIPLE_RESERVATION_PAYMENT_SUCCESS = 'RECORD_MULTIPLE_RESERVATION_PAYMENT_SUCCESS';
export const RECORD_MULTIPLE_RESERVATION_PAYMENT_FAILURE = 'RECORD_MULTIPLE_RESERVATION_PAYMENT_FAILURE';
export const RECORD_MULTIPLE_RESERVATION_PAYMENT_RESET = 'RECORD_MULTIPLE_RESERVATION_PAYMENT_RESET';
export const UPDATE_SELECTED_AGE_GROUP = 'UPDATE_SELECTED_AGE_GROUP';
export const UPDATE_SELECTED_SPORT = 'UPDATE_SELECTED_SPORT';
export const UPDATE_SELECTED_EVENT = 'UPDATE_SELECTED_EVENT';
export const CHANGE_SELECTED_PITCH_SPLIT = 'CHANGE_SELECTED_PITCH_SPLIT';
export const RESERVATION_USER_DETAILS_UPDATED = 'RESERVATION_USER_DETAILS_UPDATED';
export const RESERVATION_PAYMENT_STATUS_UPDATED = 'RESERVATION_PAYMENT_STATUS_UPDATED';
export const RESERVATIONS_ERRORS_CLEARED = 'RESERVATIONS_ERRORS_CLEARED';
export const DURATION_SELECTION_UPDATED = 'DURATION_SELECTION_UPDATED';
export const RESERVATIONS_SELECTED_DURATION_RESET = 'RESERVATIONS_SELECTED_DURATION_RESET';
export const RESERVATIONS_CREATION_USER_RESET = 'RESERVATIONS_CREATION_USER_RESET';
export const RETROSPECTIVE_BOOKING_UPDATED = 'RETROSPECTIVE_BOOKING_UPDATED';
export const HANDLE_CALENDAR_PAYMENT_DIALOG = 'HANDLE_CALENDAR_PAYMENT_DIALOG';
export const RESET_RECORD_PAYMENT_STORE = 'RESET_RECORD_PAYMENT_STORE';
export const FETCH_RESERVATION_SELECTED_ROW = 'FETCH_RESERVATION_SELECTED_ROW';
export const MANAGER_CALENDAR_RESERVATIONS_TOGGLED = 'MANAGER_CALENDAR_RESERVATIONS_TOGGLED';
export const CONVERT_RESERVATION_TO_BLOCK_BOOKING = 'CONVERT_RESERVATION_TO_BLOCK_BOOKING';
export const UPDATE_IS_RESERVATION_CREATION_FROM_CALENDAR = 'UPDATE_IS_RESERVATION_CREATION_FROM_CALENDAR';

export const initialState = {
  selectedAgeGroup: null,
  retrospectiveBooking: false,
  selectedEvent: null,
  selectedPitchSplit: 1,
  userDetailsForReservation: {
    userid: '',
    firstName: '',
    lastName: '',
    teamName: '',
    phone: '',
    dialCode: '44',
    adminNotes: '',
    email: '',
    memberships: [],
    sendNotesToBooker: false,
  },
  selectedDuration: null,
  sport: null,
  requestingReservationsCreation: false,
  reservationsCreationSuccess: false,
  reservationsPaymentCreationSuccess: null,
  reservationsPaymentCreationError: null,
  reservationsCreationError: false,
  reservationsCreationPopupOpen: false,
  isReservationCreationFromCalendar: false,
  selectedPaymentStatus: 'UNPAID',
  erroredReservations: [],
  createdReservations: [],
  cancelReservations: {
    sendEmail: true,
    refundOption: 'FULLREFUND',
    cancelOption: true,
    cancelAllocations: [],
    totalRefundAmount: 0,
    totalRefundAmountExTax: 0,
    cancellationReason: null,
    cancellationReasonText: null,
  },
  cancelledReservations: [],
  cancelledReservationsSuccess: null,
  cancelledReservationsError: null,
  recordPaymentBookings: {
    paymentType: 'FULL_AMOUNT',
    paymentMethod: 'BANKTRANSFER',
    paidAt: null,
    isPaymentProcessing: false,
  },
  calandarPaymentDialogOpen: false,
};

// Creates
export function requestManagerReservationCreation() {
  return { type: RESERVATIONS_CREATION_REQUESTED };
}

export function succeedManagerReservationCreation(createdReservations) {
  return { type: RESERVATIONS_CREATION_SUCCEEDED, createdReservations };
}

export function updateRetrospectiveBooking(retrospectiveBooking) {
  return { type: RETROSPECTIVE_BOOKING_UPDATED, retrospectiveBooking };
}

export function failManagerReservationCreation(error, erroredReservations) {
  return {
    type: RESERVATIONS_CREATION_FAILED,
    error,
    erroredReservations,
  };
}

export function deleteReservationsErrors() {
  return {
    type: RESERVATIONS_ERRORS_CLEARED,
  };
}

export const resetManagerReservationCreation = () => (
  { type: RESERVATIONS_CREATION_RESET }
);
export const resetUserManagerReservationCreation = () => (
  { type: RESERVATIONS_CREATION_USER_RESET }
);

export const updateReservationCreationType = (selectedType) => (
  {
    type: RESERVATIONS_CREATION_TYPE_UPDATED,
    selectedType,
  });

// Updates
export function updateReservation(reservationId, reqBody) {
  return { type: UPDATE_RESERVATION, reservationId, reqBody };
}

export function resetSelectedDuration() {
  return { type: RESERVATIONS_SELECTED_DURATION_RESET };
}

export function cancelReservations(reservations, cancelReservationsIn, singularBooking, options) {
  return {
    type: CANCEL_RESERVATIONS, reservations, cancelReservationsIn, singularBooking, options,
  };
}

export function cancelReservationsFailure(reservations, error) {
  return { type: CANCEL_RESERVATIONS_FAILURE, reservations, error };
}
export function resetCancelledReservationStore() {
  return { type: RESET_CANCELLED_RESERVATIONS_STORE };
}
export function updateCancelReservationStore(keyWithValue) {
  return { type: UPDATE_CANCEL_RESERVATIONS_STORE, keyWithValue };
}

export function cancelReservationsSuccess(reservations) {
  return { type: CANCEL_RESERVATIONS_SUCCESS, reservations };
}

export function updateRecordPaymentStore(keyValue) {
  return { type: UPDATE_RECORD_PAYMENT_STORE, keyValue };
}
export function recordMultipleReservationPayment(reservations, singularBooking, options) {
  return {
    type: RECORD_MULTIPLE_RESERVATION_PAYMENT, reservations, singularBooking, options,
  };
}
export function recordMultipleReservationPaymentSuccess() {
  return { type: RECORD_MULTIPLE_RESERVATION_PAYMENT_SUCCESS };
}
export function recordMultipleReservationPaymentReset() {
  return { type: RECORD_MULTIPLE_RESERVATION_PAYMENT_RESET };
}
export function recordMultipleReservationPaymentFailure(error) {
  return { type: RECORD_MULTIPLE_RESERVATION_PAYMENT_FAILURE, error };
}

export function toggleManagerReservationsCreationPopup() {
  return { type: RESERVATIONS_CREATION_POPUP_TOGGLED };
}

export function updateSelectedAgeGroup(ageGroup) {
  return {
    type: UPDATE_SELECTED_AGE_GROUP,
    ageGroup,
  };
}

export function updateSelectedSport(sport) {
  return {
    type: UPDATE_SELECTED_SPORT,
    sport,
  };
}

export const updateSelectedDuration = (duration) => ({
  type: DURATION_SELECTION_UPDATED,
  duration,
});

export function updateSelectedEvent(event) {
  return {
    type: UPDATE_SELECTED_EVENT,
    event,
  };
}

export function updateReservationPaymentStatus(selectedPaymentStatus) {
  return { type: RESERVATION_PAYMENT_STATUS_UPDATED, selectedPaymentStatus };
}

export function changeSelectedPitchSplit(pitchSplit) {
  return {
    type: CHANGE_SELECTED_PITCH_SPLIT,
    pitchSplit,
  };
}

export function updateReservationUserDetails(updatedUserDetailsForReservation) {
  return {
    type: RESERVATION_USER_DETAILS_UPDATED,
    updatedUserDetailsForReservation,
  };
}

export function handleCalendarPaymentDialog(dialogState) {
  return { type: HANDLE_CALENDAR_PAYMENT_DIALOG, dialogState };
}

export function resetRecordPaymentStore() {
  return { type: RESET_RECORD_PAYMENT_STORE };
}

export const fetchReservationSelectedRow = (id, bookingType) => ({
  type: FETCH_RESERVATION_SELECTED_ROW,
  id,
  bookingType,
});

/**
 * @param {{
 *  facility: Facility
 *  subfacility: SubFacility
 *  time: Date
 * }} event - The event that was clicked
 */
export const toggleManagerReservationsCalendar = (event) => ({
  type: MANAGER_CALENDAR_RESERVATIONS_TOGGLED,
  event,
});

/**
 * Converts the selected reservation to a block booking
 */
export function convertReservationToBlockBooking() {
  return { type: CONVERT_RESERVATION_TO_BLOCK_BOOKING };
}

export const updateIsReservationCreationFromCalendar = (data) => ({
  type: UPDATE_IS_RESERVATION_CREATION_FROM_CALENDAR,
  data,
});

const checkIfEmptyObject = (obj) => Object.entries(obj).length === 0 && obj.constructor === Object;

const handleUserUpdates = (incomingUserUpdate) => {
  if (checkIfEmptyObject(incomingUserUpdate)) {
    return initialState.userDetailsForReservation;
  }
  return incomingUserUpdate;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_SPORT:
      return {
        ...state,
        sport: action.sport,
      };
    case UPDATE_SELECTED_AGE_GROUP:
      return {
        ...state,
        selectedAgeGroup: action.ageGroup,
      };
    case UPDATE_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.event,
      };
    case RETROSPECTIVE_BOOKING_UPDATED:
      return {
        ...state,
        retrospectiveBooking: action.retrospectiveBooking,
      };
    case DURATION_SELECTION_UPDATED:
      return { ...state, selectedDuration: action.duration };
    case UPDATE_RECORD_PAYMENT_STORE:
      return {
        ...state,
        recordPaymentBookings:
              {
                ...state.recordPaymentBookings,
                ...action.keyValue,
              },
      };
    case RECORD_MULTIPLE_RESERVATION_PAYMENT_SUCCESS: {
      const updatedState = (
        state.recordPaymentBookings.user
        || state.recordPaymentBookings.isAnonymousPayment
      )
        ? {
          recordPaymentBookings: {
            ...state.recordPaymentBookings,
            user: null,
            paidAt: null,
            note: '',
          },
        } : {
          reservationsPaymentCreationSuccess: true,
          recordPaymentBookings: {
            paymentMethod: 'CARD',
            paidAt: null,
            note: '',
          },
        };

      return {
        ...state,
        ...updatedState,
      };
    }
    case RECORD_MULTIPLE_RESERVATION_PAYMENT_FAILURE:
      return {
        ...state,
        reservationsPaymentCreationSuccess: false,
        reservationsPaymentCreationError: action.error,
      };
    case RECORD_MULTIPLE_RESERVATION_PAYMENT_RESET:
      return {
        ...state,
        reservationsPaymentCreationSuccess: initialState.reservationsPaymentCreationSuccess,
        reservationsPaymentCreationError: initialState.reservationsPaymentCreationError,
        calandarPaymentDialogOpen: false,
      };
    case CHANGE_SELECTED_PITCH_SPLIT:
      return { ...state, selectedPitchSplit: action.pitchSplit };
    case RESERVATION_USER_DETAILS_UPDATED:
      return {
        ...state,
        userDetailsForReservation:
        {
          ...state.userDetailsForReservation,
          ...handleUserUpdates(action.updatedUserDetailsForReservation),
        },
      };
    case RESERVATIONS_CREATION_REQUESTED:
      return {
        ...state,
        requestingReservationsCreation: true,
        reservationsCreationError: false,
        reservationsCreationSuccess: false,
      };
    case RESERVATIONS_CREATION_SUCCEEDED:
      return {
        ...state,
        requestingReservationsCreation: false,
        reservationsCreationSuccess: true,
        userDetailsForReservation: initialState.userDetailsForReservation,
        selectedPitchSplit: initialState.selectedPitchSplit,
        createdReservations: action.createdReservations,
      };
    case RESERVATION_PAYMENT_STATUS_UPDATED:
      return { ...state, selectedPaymentStatus: action.selectedPaymentStatus };
    case RESERVATIONS_CREATION_FAILED:
      return {
        ...state,
        requestingReservationsCreation: false,
        reservationsCreationError: true,
        erroredReservations: action.erroredReservations,
      };
    case CANCEL_RESERVATIONS_FAILURE:
      return {
        ...state,
        cancelledReservations: action.reservations,
        cancelledReservationsSuccess: false,
        cancelledReservationsError: action.error,
      };
    case CANCEL_RESERVATIONS_SUCCESS:
      return {
        ...state,
        cancelledReservations: action.reservations,
        cancelledReservationsSuccess: true,
        cancelledReservationsError: null,
      };
    case RESET_CANCELLED_RESERVATIONS_STORE:
      return {
        ...state,
        cancelReservations: initialState.cancelReservations,
        cancelledReservations: initialState.cancelledReservations,
        cancelledReservationsSuccess: initialState.cancelledReservationsSuccess,
        cancelledReservationsError: initialState.cancelledReservationsError,
      };
    case UPDATE_CANCEL_RESERVATIONS_STORE:
      return {
        ...state,
        cancelReservations: {
          ...state.cancelReservations,
          ...action.keyWithValue,
        },
      };
    case RESERVATIONS_ERRORS_CLEARED:
      return {
        ...state, erroredReservations: [],
      };
    case RESERVATIONS_SELECTED_DURATION_RESET:
      return {
        ...state, selectedDuration: initialState.selectedDuration,
      };
    case RESERVATIONS_CREATION_POPUP_TOGGLED:
      return {
        ...state,
        reservationsCreationPopupOpen: !state.reservationsCreationPopupOpen,
        userDetailsForReservation: { ...initialState.userDetailsForReservation },
      };
    case RESERVATIONS_CREATION_USER_RESET:
      return { ...state, userDetailsForReservation: { ...initialState.userDetailsForReservation } };
    case RESERVATIONS_CREATION_TYPE_UPDATED:
      return { ...state, reservationsCreationType: action.selectedType };
    case '@@router/LOCATION_CHANGE':
    case RESERVATIONS_CREATION_RESET:
      return initialState;

    case HANDLE_CALENDAR_PAYMENT_DIALOG:
      return {
        ...state,
        calandarPaymentDialogOpen: action.dialogState,
      };
    case RESET_RECORD_PAYMENT_STORE:
      return {
        ...state,
        recordPaymentBookings: initialState.recordPaymentBookings,
      };
    case UPDATE_IS_RESERVATION_CREATION_FROM_CALENDAR:
      return {
        ...state,
        isReservationCreationFromCalendar: action.data,
      };

    default:
      return state;
  }
};
