/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Typography, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { useCompany } from '../../../hooks';
import { BreakdownCard } from './BreakdownCard';
import { paymentBreakdownConstants } from './constants';

export const PermanentPaymentBreakdown = ({ orders }) => {
  const company = useCompany();
  const { currencySym } = company;
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethodAmounts, setPaymentMethodAmounts] = useState({});
  const [paymentMethodOrderCounts, setPaymentMethodOrderCounts] = useState({});

  useEffect(() => {
    let totalSum = 0;
    const amountsByPaymentMethod = {};
    const countsByPaymentMethod = {};

    const nonPartialOrders = orders.filter((order) => order.paymentMethod !== 'PARTIAL');
    if (nonPartialOrders.length > 0) {
      nonPartialOrders.forEach((order) => {
        const amount = order.total / 100;
        if (order.status === 'CREATED') {
          totalSum += amount;
          amountsByPaymentMethod[order.paymentMethod] = (amountsByPaymentMethod[order.paymentMethod] || 0) + amount;
          countsByPaymentMethod[order.paymentMethod] = (countsByPaymentMethod[order.paymentMethod] || 0) + 1;
        } else if (order.status === 'REFUNDED') {
          totalSum -= amount;
          amountsByPaymentMethod[order.paymentMethod] = (amountsByPaymentMethod[order.paymentMethod] || 0) - amount;
          countsByPaymentMethod[order.paymentMethod] = (countsByPaymentMethod[order.paymentMethod] || 0) + 1;
        }
      });

      setTotalAmount(totalSum);
      setPaymentMethodAmounts(amountsByPaymentMethod);
      setPaymentMethodOrderCounts(countsByPaymentMethod);
    }
  }, [orders]);

  const paymentMethodEntries = Object.entries(paymentMethodAmounts);

  return (
    <Accordion
      elevation={0}
      style={{
        backgroundColor: '#F5F5F5',
        border: '1px solid #ddd',
        borderRadius: '0.75rem 0.75rem 0 0',
        margin: '1rem 2rem 0 2rem',
      }}
      expanded
    >
      <AccordionSummary>
        <Typography style={{ fontWeight: '600' }}>Payment Method Breakdown</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
            gap: '1rem',
          }}
        >
          {/* Total Card */}
          <BreakdownCard
            title="TOTAL"
            count={orders.length}
            type="TOTAL"
            total={totalAmount.toFixed(2)}
            currencySym={currencySym}
            totalOrderAmount={totalAmount}
            componentType="methods"
          />

          {/* Payment Method Cards */}
          {paymentMethodEntries.map(([method, amount]) => (
            <BreakdownCard
              key={method}
              title={paymentBreakdownConstants[method]?.label || method}
              count={paymentMethodOrderCounts[method] ?? 0}
              type={method}
              total={amount.toFixed(2)}
              currencySym={currencySym}
              totalOrderAmount={totalAmount}
              componentType="methods"
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
