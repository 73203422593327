import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  Radio, RadioGroup, FormLabel, FormControl, FormControlLabel, Select, MenuItem, useMediaQuery,
} from '@material-ui/core';
import { useCompany } from '../hooks';

const styles = (theme) => ({
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  select: {
    minWidth: 200,
  },
});

const PaymentRadioStatus = ({
  classes, paymentStatus, setPaymentStatus, disabled,
}) => {
  const { products } = useCompany();
  const [value, setValue] = useState(paymentStatus);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const isCardNotPresentEnabled = products?.cardNotPresent === 'ENABLED';
  const isTerminalEnabled = products?.terminal === 'ENABLED';

  const handleChange = (event) => {
    setValue(event.target.value);
    setPaymentStatus(event.target.value);
  };

  const paymentOptions = [
    { value: 'BANKTRANSFER', label: 'Paid - Bank Transfer' },
    { value: 'CASH', label: 'Paid - Cash' },
    { value: 'CARD', label: 'Paid - Card (Other)' },
    { value: 'NOT_CHARGED', label: 'Not charged' },
  ];

  if (paymentStatus === 'STRIPE') {
    paymentOptions.unshift({ value: 'STRIPE', label: 'Paid - Stripe' });
  }

  if (isCardNotPresentEnabled) {
    paymentOptions.push({ value: 'CARD_NOT_PRESENT', label: 'Card On File' });
  }

  if (isTerminalEnabled) {
    paymentOptions.push({ value: 'TERMINAL', label: 'Terminal' });
  }

  // Unpaid should always be the last option
  paymentOptions.push({ value: 'UNPAID', label: 'Unpaid' });

  return (
    <FormControl component="fieldset" required>
      <FormLabel component="legend">Payment Status</FormLabel>
      {isMobile ? (
        <Select
          value={value}
          disabled={disabled}
          onChange={handleChange}
          className={classes.select}
        >
          {paymentOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <RadioGroup
          aria-label="payment-status"
          name="payment-status"
          className={classes.group}
          value={value}
          onChange={handleChange}
        >
          {paymentOptions.map((option) => (
            <FormControlLabel
              disabled={disabled}
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      )}
    </FormControl>
  );
};

PaymentRadioStatus.propTypes = {
  classes: PropTypes.shape().isRequired,
  paymentStatus: PropTypes.isRequired,
  setPaymentStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PaymentRadioStatus.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(PaymentRadioStatus);
