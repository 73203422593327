/* eslint-disable react/prop-types */
import React from 'react';
import { BreakdownCard } from './BreakdownCard';

export const ProductBreakdownCards = ({
  productCardData, selectedOptions, currencySym, showTotal,
}) => {
  const data = productCardData.filter(({ type }) => selectedOptions.map(
    ({ value }) => value,
  ).includes(type));

  const totalOrderAmount = productCardData.reduce(
    (acc, { total, refundTotal }) => acc + (total - refundTotal), 0,
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: '1rem',
      }}
    >
      {showTotal && (
        <BreakdownCard
          key="total"
          title="Total Revenue"
          count={null}
          type="total"
          total={(totalOrderAmount / 100).toFixed(2)}
          currencySym={currencySym}
          totalOrderAmount={totalOrderAmount / 100}
          componentType="total"
        />
      )}
      {data.map(({
        title, count, type, total, refundTotal,
      }) => (
        count > 0 && (
          <BreakdownCard
            key={type}
            title={title}
            count={count}
            type={type}
            total={((total - refundTotal) / 100).toFixed(2)}
            currencySym={currencySym}
            totalOrderAmount={totalOrderAmount / 100}
            componentType="products"
          />
        )
      ))}
    </div>
  );
};
