import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography, CircularProgress, Box, Button,
} from '@material-ui/core';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { getOrderService, refundOrderService } from '../../services/ordersServices';
import PayoutOrdersTable from '../payouts/PayoutTable';
import { valueToTagMap } from './OrderTable';
import { useCompany, usePitchbookingUser, useToast } from '../../hooks';
import { formatLocalDate } from '../../utils';
import RefundDialog from './RefundDialog';

const LoadingState = () => (
  <div style={{ padding: '2rem' }}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="200px"
      bgcolor="#fff"
      borderRadius={4}
      boxShadow={1}
      p={3}
    >
      <CircularProgress size={60} thickness={4} />
      <Typography variant="h6" style={{ marginTop: 16 }}>
        Loading order details...
      </Typography>
    </Box>
  </div>
);

const ErrorState = () => (
  <div style={{ padding: '2rem' }}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="200px"
      bgcolor="#fff"
      borderRadius={4}
      boxShadow={1}
      p={3}
    >
      <Typography variant="h6" color="error" style={{ marginBottom: 16 }}>
        Error Loading Order Details
      </Typography>
      <Typography variant="body1" color="textSecondary" align="center" style={{ marginBottom: 16 }}>
        There was a problem loading the order information. Please try again.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.location.reload()}
      >
        Retry
      </Button>
    </Box>
  </div>
);

const Payment = ({
  match, companyId, stripeId,
}) => {
  const { orderId } = match.params;
  const toast = useToast();
  const queryClient = useQueryClient();
  const { currencySym, timezone, dateLocale } = useCompany();
  const [isRefundDialogOpen, setIsRefundDialogOpen] = useState(false);

  // TODO: Remove this once we have verified it working with Soccer 5s
  const { isPitchbookingManager } = usePitchbookingUser();
  const isRefundAllowed = isPitchbookingManager || companyId === '11588599-81b0-4bf3-a419-ea90bfa92542';

  const {
    data: order, isLoading, isError, isFetching,
  } = useQuery({
    queryKey: ['order', orderId],
    queryFn: async () => {
      const res = await getOrderService(companyId, orderId);
      if (!res.data) throw new Error('Order not found');
      return res.data;
    },
    enabled: !!companyId && !!orderId,
  });

  const refundMutation = useMutation({
    mutationFn: async () => {
      const res = await refundOrderService(companyId, order.id);
      if (res && res.status !== 200) {
        throw new Error('Refund failed!');
      }

      toast.trigger({
        message: 'Refund successful!',
        type: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['order', orderId] });
      setIsRefundDialogOpen(false);

      return res;
    },
    onError: () => {
      toast.trigger({
        message: 'Refund failed!',
        type: 'error',
      });
      queryClient.invalidateQueries({ queryKey: ['order', orderId] });
    },
  });

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError) {
    return <ErrorState />;
  }

  // Show the refunded information
  if (order?.status === 'REFUNDED') {
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '0.5rem', backgroundColor: '#fff', padding: 20,
      }}
      >
        {order && order !== null && order.user && (
          <>
            {/* Paid at */}
            <Typography variant="subtitle1">
              {`Refunded at: ${formatLocalDate(order.createdAt, timezone, dateLocale)}`}
            </Typography>

            {/* Amount */}
            <Typography variant="subtitle1">
              {`Refund amount: ${currencySym}${(order.total / 100).toFixed(2)} (${currencySym}${order.totalExTax?.toFixed(2)} + ${currencySym}${order.tax?.toFixed(2)} tax)`}
            </Typography>

            {/* Refund method */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <Typography variant="subtitle1">
                Refund method:
              </Typography>
              {valueToTagMap(order.paymentMethod, stripeId, order.chargeId)}

              {order?.paymentMethod === 'STRIPE' && (
                <Typography variant="subtitle1">
                  <a href={`https://dashboard.stripe.com/${stripeId}/payments/${order.chargeId}`} target="_blank" rel="noopener noreferrer">View in Stripe</a>
                </Typography>
              )}
            </div>

            {/* Original */}
            {order.originalOrder && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>

                <Typography variant="subtitle1">
                  {`Original Payment at: ${formatLocalDate(order.originalOrder.createdAt, timezone, dateLocale)}`}
                </Typography>
                <Link to={`/payments/${order.originalOrder.id}`}>View Original Order</Link>
              </div>
            )}

            <PayoutOrdersTable
              payout={order}
              isOrder
              stripeCompanyId={stripeId}
              currencySym={currencySym}
              timezone={timezone}
              dateLocale={dateLocale}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', gap: '0.5rem', backgroundColor: '#fff', padding: 20,
    }}
    >
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {`Payment for Order: ${orderId}`}
      </Typography>

      {order && order !== null && order.user && (
        <>
          {/* User */}
          <Typography variant="subtitle1">
            {'Payment made by: '}
            {order.user && !order.isAnonymousPayment
              ? (
                <a href={`/users/${order.user.id}`}>
                  {`${order.user.firstName} ${order.user.lastName}`}
                </a>
              )
              : `${order.note} (No Account)`}

          </Typography>

          {/* Paid at */}
          <Typography variant="subtitle1">
            {`Payment made at: ${formatLocalDate(order.paidAt, timezone, dateLocale)}`}
          </Typography>

          {/* Amount */}
          <Typography variant="subtitle1">
            {`Payment amount: ${currencySym}${(order.total / 100).toFixed(2)} (${currencySym}${order.totalExTax?.toFixed(2)} + ${currencySym}${order.tax?.toFixed(2)} tax)`}
          </Typography>

          {/* Payment method */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Typography variant="subtitle1">
              Payment method:
            </Typography>
            {valueToTagMap(order.paymentMethod, stripeId, order.chargeId)}

            {order?.paymentMethod === 'STRIPE' && (
            <Typography variant="subtitle1">
              <a href={`https://dashboard.stripe.com/${stripeId}/payments/${order.chargeId}`} target="_blank" rel="noopener noreferrer">View in Stripe</a>
            </Typography>
            )}
          </div>

          {/* Refunded */}
          {order.refundedOrder && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>

              <Typography variant="subtitle1">
                {`Payment Refunded at: ${formatLocalDate(order.refundedOrder.createdAt, timezone, dateLocale)}`}
              </Typography>
              <Link to={`/payments/${order.refundedOrder.id}`}>View Refunded Order</Link>
            </div>
          )}

          {/* // TODO: Remove isRefundAllowed once we have verified it working with Soccer 5s */}
          {isRefundAllowed && !order.refundedOrder && !order.originalOrder && (
            <>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsRefundDialogOpen(true)}
                  disabled={refundMutation.isLoading || isFetching}
                >
                  Refund Payment
                </Button>
              </div>
              <RefundDialog
                open={isRefundDialogOpen}
                onClose={() => setIsRefundDialogOpen(false)}
                onConfirm={() => refundMutation.mutate()}
                isLoading={refundMutation.isLoading}
                order={order}
                currencySym={currencySym}
              />
            </>
          )}

          <PayoutOrdersTable
            payout={order}
            isOrder
            stripeCompanyId={stripeId}
            currencySym={currencySym}
            timezone={timezone}
            dateLocale={dateLocale}
          />
        </>
      )}

      {/* Loading */}
      {!order && <Typography variant="subtitle1"> Loading...</Typography>}
    </div>
  );
};

Payment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  companyId: PropTypes.string.isRequired,
  stripeId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: state.companies.companyInfo.id,
  timezone: state.companies.companyInfo.timezone,
  stripeId: state.companies.companyInfo.stripeId,
});

export default connect(mapStateToProps, null)(Payment);
