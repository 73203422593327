/* eslint-disable react/destructuring-assignment */
import React from 'react';
import moment from 'moment';
import MaterialTable from 'material-table';
import '../../../styles/order.css';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import IdTag from '@pitchbooking-dev/pb-shared/lib/components/IdTag';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import { Typography } from '@material-ui/core';
import { generateOrderStatusTypeTag } from '../../payments/OrderTable';
import { PartialPaymentsNoteModal } from '../../bookings/components/bookingsView/PartialPaymentsNoteModal';

const SubscriptionStripeOrdersTable = (props) => {
  const { subscription, stripeCompanyId, timezone } = props;
  const { orders } = subscription;
  const stripeLinkBase = `https://dashboard.stripe.com/${stripeCompanyId}/payments/`;
  return (
    <ConditionallyVisible condition={orders && orders.length > 0}>
      <MaterialTable
        style={{ margin: '2rem 0' }}
        data={orders.filter((order) => order?.paymentMethod !== 'PARTIAL')}
        title="Subscription Payments:"
        icons={{
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          Export: () => <SaveAlt />,
          ResetSearch: () => <Clear />,
          SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
        }}
        columns={[
          {
            title: 'Order ID',
            field: 'id',
            render: (row) => (
              <a href={`/payments/${row.id}`}>
                <IdTag title={row.id} />
              </a>
            ),
          },
          {
            title: 'Payment ID',
            field: 'paymentId',
            render: (row) => (<IdTag title={row.paymentId} url={`${stripeLinkBase}${row.paymentId}`} />),
          },
          {
            title: 'Amount',
            field: 'total',
            render: (row) => `${(row.status === 'REFUNDED') ? '-' : ''}${(row.total / 100).toFixed(2)}`,
          },
          {
            title: 'Paid',
            field: 'paidAt',
            render: (row) => (<div>{moment.tz(row.paidAt, timezone).format('DD MMM YY, HH:mm')}</div>),
          },
          {
            title: 'Slot',
            field: 'subscription_order.slot',
            render: (row) => (<div>{moment.tz(row.subscription_order.slot, timezone).format('DD MMM YY, HH:mm')}</div>),
            // defaultSort: 'desc',
          },
          {
            title: 'Status',
            field: 'status',
            render: (row) => (generateOrderStatusTypeTag(row.status)),
          },
          {
            title: 'Partial Payment',
            field: 'partial',
            align: 'center',
            render: (row) => (row.partial
              ? <CheckCircleTwoToneIcon style={{ color: 'rgb(43, 227, 160)' }} />
              : <Typography variant="body2">Paid in full</Typography>
            ),
          },
          {
            title: 'Notes',
            align: 'center',
            sorting: false,
            render: (row) => {
              if (row.note) {
                if (row?.note?.length > 30) {
                  return <PartialPaymentsNoteModal note={row.note} />;
                }
                return row.note;
              }
              return null;
            },
          },
        ]}
        options={{
          search: false,
          tableLayout: 'auto',
          exportButton: true,
          emptyRowsWhenPaging: false,
          exportFileName: 'Pitchbooking Subscription Stripe Orders',
          pageSize: 25,
          pageSizeOptions: [10, 25, 50, 100],
          showFirstLastPageButtons: false,
          showTitle: true,
          headerStyle: {
            backgroundColor: '#efefef',
            fontWeight: 'bold',
          },
        }}
        localization={{
          toolbar: {
            exportTitle: 'Download',
          },

        }}
      />
    </ConditionallyVisible>
  );
};

SubscriptionStripeOrdersTable.propTypes = {
  subscription: PropTypes.shape().isRequired,
  stripeCompanyId: PropTypes.string,
  timezone: PropTypes.string,
};

SubscriptionStripeOrdersTable.defaultProps = {
  stripeCompanyId: null,
  timezone: 'Europe/London',
};

export default SubscriptionStripeOrdersTable;
