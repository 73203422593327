/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import DateSelectorWithNextAndPreviousDay from './DateSelectorWithNextAndPreviousDay';
import MultiSiteSelector from './MultiSiteSelector';
import BasicSiteSelector from './BasicSiteSelector';
import { useCompany } from '../../hooks';

const DateWithSitesWrapper = ({
  selectedSiteId, sites, afterChange, changeSite, disabled, calendarIsLoading, showTodayButton,
}) => {
  const { products } = useCompany();
  const { multiSiteSelector } = products;

  return (
    <div>
      <DateSelectorWithNextAndPreviousDay
        afterChange={afterChange}
        disabled={disabled}
        showTodayButton={showTodayButton}
      />
      {multiSiteSelector && multiSiteSelector?.toLowerCase() === 'enabled'
        ? (
          <MultiSiteSelector
            selectedSiteId={selectedSiteId}
            sites={sites}
            changeSite={changeSite}
            disabled={disabled}
            calendarIsLoading={calendarIsLoading}
          />
        ) : (
          <BasicSiteSelector
            selectedSiteId={selectedSiteId}
            sites={sites}
            changeSite={changeSite}
          />
        )}
    </div>
  );
};

DateWithSitesWrapper.propTypes = {
  selectedSiteId: PropTypes.string.isRequired,
  sites: PropTypes.shape().isRequired,
  afterChange: PropTypes.func.isRequired,
  changeSite: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  calendarIsLoading: PropTypes.bool,
  showTodayButton: PropTypes.bool,
};

DateWithSitesWrapper.defaultProps = {
  disabled: false,
  calendarIsLoading: false,
  showTodayButton: false,
};

export default (DateWithSitesWrapper);
