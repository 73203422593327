import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, batch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  FormControlLabel, Checkbox, TextField, Avatar,
} from '@material-ui/core';
import MenuHeading from '../../components/MenuHeading';
import OrderTable from './OrderTable';
import { getCompanyAddons, updatePOSOrder, updatePOSTotal } from '../../reducers/addonsReducer';
import { useCompany, usePitchbookingUser } from '../../hooks';

function ProductSelection({ paymentMethod, disabled, siteId }) {
  const dispatch = useDispatch();
  const { products, currency } = useCompany();
  const { siteAccess } = usePitchbookingUser();

  // it will set the pos reservation siteId to that of the addon siteId,
  // then the associated res/sub sitedId, then as fallback localStorage
  const preferredPosSiteId = siteId || JSON.parse(localStorage.getItem('preferredPosSite'))?.id || null;

  const addons = useSelector((state) => {
    const { addons } = state.addons;

    if (!addons || addons?.length === 0) return [];

    return addons.filter(
      (x) => x.children.length === 0 && (x.trackStock === false || x.currentStock > 0),
    );
  });
  const order = useSelector((state) => state.addons.posOrder);

  const allAddons = useMemo(() => {
    const filteredAddons = addons;
    if (siteId) {
      return addons?.filter((addon) => (addon?.siteId === siteId || addon?.siteId === null)
    && addon.status === 'ACTIVE');
    }
    return siteAccess.length === 0
      ? filteredAddons
      : filteredAddons?.filter(
        (addon) => siteAccess.includes(addon.siteId) || addon.siteId === null,
      );
  }, [addons, siteAccess, siteId]);

  const [addAdditionalProducts, setAddAdditionalProducts] = useState(false);

  useEffect(() => {
    dispatch(getCompanyAddons(null, null, true));
  }, []);

  useEffect(() => {
    if (order.length === 0) {
      setAddAdditionalProducts(false);
    }
  }, [order]);

  const updatePosState = (newOrder) => {
    batch(() => {
      dispatch(
        updatePOSOrder(newOrder),
      );
      dispatch(
        updatePOSTotal(newOrder.reduce((acc, addon) => acc + (addon.price), 0)?.toFixed(2)),
      );
    });
  };

  const handleOrderChange = (existingOrder, changeType, addon) => {
    let newOrder;

    switch (changeType) {
      case 'REMOVE': {
        newOrder = existingOrder.map((orderItem) => {
          if (orderItem.id === addon.id) {
            if (orderItem.quantity === 1) {
              return null;
            }

            return {
              ...orderItem,
              quantity: orderItem.quantity - 1,
              price: orderItem.price - addon.priceModifiers[0]?.value,
            };
          }
          return orderItem;
        }).filter((orderItem) => orderItem !== null);
        break;
      }
      case 'ADD': {
        newOrder = existingOrder.map((orderItem) => {
          if (orderItem.id === addon.id) {
            return {
              ...orderItem,
              quantity: orderItem.quantity + 1,
              price: orderItem.price + addon.priceModifiers[0]?.value,
            };
          }
          return { ...orderItem, siteId: orderItem.siteId ? orderItem.siteId : preferredPosSiteId };
        });
        break;
      }
      default: {
        return;
      }
    }

    updatePosState(newOrder);
  };

  const isProductAvailable = (addon) => {
    if (addon.trackStock === false) return true;
    const currentlySelected = order.find((orderItem) => orderItem.id === addon?.id)?.quantity;
    const currentStock = addon?.currentStock;
    if (currentlySelected) {
      return currentStock - currentlySelected > 0;
    }
    return currentStock > 0;
  };

  const updateOrder = (addon) => {
    let newOrder;
    const existingAddon = order.find((orderItem) => orderItem.id === addon.id);

    if (existingAddon) {
      // Update quantity of the existing addon
      newOrder = order.map((orderItem) => {
        if (orderItem.id === addon.id) {
          return {
            ...orderItem,
            quantity: orderItem.quantity + 1,
            price: orderItem.price + addon.priceModifiers[0]?.value,
          };
        }
        return orderItem;
      });
    } else {
      // Add new addon to the order with initial quantity
      newOrder = [...order,
        {
          ...addon,
          quantity: 1,
          price: addon.priceModifiers[0].value,
          siteId: addon.siteId ? addon.siteId : preferredPosSiteId,
        }];
    }

    updatePosState(newOrder);
  };

  if (products.pos !== 'ENABLED') {
    return null;
  }

  return (
    <div>
      <MenuHeading title="Product Selection" />
      <FormControlLabel
        control={(
          <Checkbox
            disabled={disabled}
            checked={addAdditionalProducts ?? false}
            onChange={(e) => {
              setAddAdditionalProducts(e.target.checked);
            }}
          />
        )}
        label="Add additional products?"
      />
      {addAdditionalProducts && (
        <div>
          <Autocomplete
            disableClearable
            onChange={(event, option) => updateOrder(option)}
            options={allAddons.sort((a, b) => {
              const nameComparison = a.name.localeCompare(b.name);
              if (nameComparison !== 0) return nameComparison;
              return (a.site?.name || '').localeCompare(b.site?.name || '');
            })}
            renderInput={(params) => <TextField {...params} label="Select Product" variant="outlined" fullWidth />}
            getOptionLabel={(option) => `${option?.site?.name ? `${option?.site?.name} - ` : ''}${option.name}`}
            renderOption={(option) => (
              <div style={{ display: 'flex', alignItems: 'center', height: '80px' }}>
                {' '}
                <Avatar
                  src={option.photoUrl || <></>}
                  alt={option.name}
                  variant="square"
                  style={{ marginRight: 10, width: 60, height: 60 }}
                />
                {`${option.name}${option?.site?.name ? ` (${option?.site?.name})` : ''}`}
              </div>
            )}
            disabled={paymentMethod === 'TERMINAL'}
          />
          <OrderTable
            order={order}
            handleOrderChange={handleOrderChange}
            isProductAvailable={isProductAvailable}
            currency={currency}
            disabled={paymentMethod === 'TERMINAL'}
          />
        </div>
      )}
    </div>
  );
}

ProductSelection.propTypes = {
  paymentMethod: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  siteId: PropTypes.string,
};

ProductSelection.defaultProps = {
  disabled: false,
  siteId: PropTypes.string,
};

ProductSelection.defaultProps = {
  siteId: null,
};

export default ProductSelection;
