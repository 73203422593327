import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import { Button, Typography } from '@material-ui/core';
import ToggleButtons from '../../../../components/ToggleButtons';
import MenuHeading from '../../../../components/MenuHeading';
import * as actions from '../../../../reducers/subscriptionsReducer';
import CustomTextbox from '../../../../shared-components/CustomTextBoxWithLabel';
import { pickProperties } from '../../../../utils';
import { useCompanyProduct } from '../../../../hooks';

const RECURRENCE_OPTIONS = {
  INDEFINITELY: {
    buttonTitle: 'Indefinitely',
    buttonValue: 'INDEFINITELY',
  },
  ENDDATE: {
    buttonTitle: 'Set End Date',
    buttonValue: 'ENDDATE',
  },
  NUMOFWEEKS: {
    buttonTitle: 'Set Number of Weeks',
    buttonValue: 'NUMOFWEEKS',
  },
};

const ADD_ON_OPTIONS = [
  {
    buttonTitle: 'No',
    buttonValue: false,
  },
  {
    buttonTitle: 'Yes',
    buttonValue: true,
  },
];

const BlockBookingStepperRecurrence = ({
  updateSubscriptionStore,
  subscription,
  facility,
}) => {
  const {
    validFrom,
    validTo,
    isAddOns,
    addOns = [],
    validToType,
    numOfWeeks,
  } = subscription;
  const isSubscriptionAmenitiesEnabled = useCompanyProduct('subscriptionAmenities');

  const handleRecurrenceToggle = (validToType) => {
    const updates = { validToType };

    if (validToType === 'INDEFINITELY') {
      updates.validTo = null;
      updates.numOfWeeks = null;
    }

    updateSubscriptionStore(updates);
  };

  const handleEndDate = (date) => {
    if (!date) return;

    const endDate = date.endOf('day');
    const weeksDiff = endDate.diff(validFrom, 'weeks') + 1;

    updateSubscriptionStore({
      validTo: endDate.format(),
      numOfWeeks: weeksDiff,
    });
  };

  const handleNumOfWeeks = (numWeeks) => {
    if (!numWeeks || Number.isNaN(numWeeks)) return;

    const endDate = moment(validFrom)
      .add(numWeeks - 1, 'weeks')
      .endOf('day');

    updateSubscriptionStore({
      numOfWeeks,
      validTo: endDate.format(),
    });
  };

  const handleAddOnToggle = () => {
    updateSubscriptionStore({
      addOns: !isAddOns ? [] : null,
      isAddOns: !isAddOns,
    });
  };

  const getAddonPrice = (addons) => addons.reduce(
    (acc, x) => {
      if (x.pricePer === 'HOUR') {
        const difference = subscription.endTimeFormatted.clone().add(1, 's').diff(
          subscription.startTimeFormatted.clone(), 'minutes',
        );
        return acc + (x.price * (difference / 60));
      }
      return acc + (x.price * (x.quantity ?? 1));
    },
    0,
  );

  const handleAddOnQuantityChange = async (addon, quantity) => {
    const existingAddOnIndex = addOns.findIndex((a) => a.id === addon.id);
    const newAddOns = [...addOns];

    if (quantity <= 0) {
      if (existingAddOnIndex !== -1) {
        newAddOns.splice(existingAddOnIndex, 1);
      }
    } else if (existingAddOnIndex !== -1) {
      newAddOns[existingAddOnIndex] = { ...addon, quantity };
    } else {
      newAddOns.push({ ...addon, quantity });
    }

    const addonPrice = getAddonPrice(newAddOns);

    updateSubscriptionStore({
      addOns: newAddOns,
      addOnPrice: addonPrice,
    });
  };

  const handleAddOnToggleSelection = async (addon) => {
    const existingAddOnIndex = addOns.findIndex((a) => a.id === addon.id);
    const newAddOns = [...addOns];

    if (existingAddOnIndex !== -1) {
      newAddOns.splice(existingAddOnIndex, 1);
    } else {
      newAddOns.push(addon);
    }

    const addonPrice = getAddonPrice(newAddOns);

    updateSubscriptionStore({
      addOns: newAddOns,
      addOnPrice: addonPrice,
    });
  };

  const formattedValidFrom = moment(validFrom);
  const validFromDayOfWeek = formattedValidFrom.day();
  const isDayBlocked = (day) => moment(day).day() !== validFromDayOfWeek;

  const combinedAddons = useMemo(() => {
    const addons = facility?.addons?.map((x) => pickProperties(x, 'id', 'type', 'name', 'quantity', 'status', 'price', 'pricePer', 'priceType')) || [];
    const amenities = facility?.amenities?.map((x) => pickProperties(x, 'id', 'type', 'name', 'status', 'price', 'pricePer', 'priceType')) || [];

    return [...addons, ...amenities]
      .filter((x) => x.status === 'ACTIVE')
      .sort((a, b) => {
        if (a.type !== b.type) {
          const typeOrder = {
            CONSUMABLE: 1,
            RESOURCE: 2,
            AMENITY: 3,
          };
          return typeOrder[a.type] - typeOrder[b.type];
        }
        return a.name.localeCompare(b.name);
      });
  }, [facility?.addons, facility?.amenities]);

  const renderConsumableAddon = (addon) => {
    const currentAddOn = addOns.find((y) => y.id === addon.id);
    const currentQuantity = currentAddOn?.quantity || 0;

    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleAddOnQuantityChange(addon, currentQuantity - 1)}
          style={{
            fontSize: '20px',
            height: '25px',
            margin: '10px',
          }}
          disabled={!currentAddOn || currentQuantity <= 0}
        >
          -
        </Button>
        {currentQuantity}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleAddOnQuantityChange(addon, currentQuantity + 1)}
          style={{
            fontSize: '20px',
            height: '25px',
            margin: '10px',
          }}
          disabled={addon.quantity && currentQuantity >= addon.quantity}
        >
          +
        </Button>
      </div>
    );
  };

  const renderResourceAddon = (addon) => {
    const currentAddOn = addOns.find((y) => y.id === addon.id);

    return (
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={async () => {
          await handleAddOnToggleSelection(addon);
        }}
      >
        {currentAddOn ? 'Remove' : 'Add'}
      </Button>
    );
  };

  useEffect(() => {
    updateSubscriptionStore({
      addOns: null,
      isAddOns: false,
    });
  }, [facility]);

  return (
    <>
      <div>
        <MenuHeading title="How long do you want this block booking to be reserved for?" />
        <ToggleButtons
          buttonsData={Object.values(RECURRENCE_OPTIONS)}
          changeOption={handleRecurrenceToggle}
          value={validToType}
        />

        <ConditionallyVisible condition={validToType === 'ENDDATE'}>
          <DayPickerSingleDateController
            initialVisibleMonth={() => (validTo ? moment(validTo) : moment())}
            focused
            onDateChange={handleEndDate}
            date={validTo ? moment(validTo) : null}
            noBorder
            isOutsideRange={(day) => day.isBefore(moment(validFrom))}
            isDayBlocked={isDayBlocked}
          />
        </ConditionallyVisible>

        <ConditionallyVisible condition={validToType === 'NUMOFWEEKS'}>
          <CustomTextbox
            id="numOfWeeks"
            labelText="Number of weeks"
            displayValue={numOfWeeks}
            onChangeAction={(e) => handleNumOfWeeks(e.numOfWeeks)}
          />
        </ConditionallyVisible>
      </div>

      {isSubscriptionAmenitiesEnabled && combinedAddons.length > 0 && (
        <div>
          <MenuHeading title="Do you want to add any add-ons to this block booking?" />
          <ToggleButtons
            buttonsData={ADD_ON_OPTIONS}
            changeOption={handleAddOnToggle}
            value={isAddOns}
          />

          {isAddOns && (
            <div>
              <Typography variant="body1" style={{ marginTop: '1rem' }}>
                Please select the add-ons you want to add to this block booking
              </Typography>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                {combinedAddons.map((addon) => (
                  <div
                    key={addon.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {addon.name}
                    <div>
                      {addon.type === 'CONSUMABLE'
                        ? renderConsumableAddon(addon)
                        : renderResourceAddon(addon)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

BlockBookingStepperRecurrence.propTypes = {
  subscription: PropTypes.shape({
    validFrom: PropTypes.string.isRequired,
    validTo: PropTypes.string,
    validToType: PropTypes.string.isRequired,
    numOfWeeks: PropTypes.number,
    isAddOns: PropTypes.bool,
    addOns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number,
    })),
    facilityId: PropTypes.string.isRequired,
    startTimeFormatted: PropTypes.string.isRequired,
    endTimeFormatted: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }).isRequired,
  updateSubscriptionStore: PropTypes.func.isRequired,
  facility: PropTypes.shape({
    addons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number,
    })).isRequired,
    amenities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  subscription: state.subscriptions.subscription,
  facility: state.facilities.selectedFacility,
});

const mapDispatchToProps = (dispatch) => ({
  updateSubscriptionStore: (keyWithValue) => dispatch(
    actions.updateSubscriptionStore(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockBookingStepperRecurrence);
