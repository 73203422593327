// @flow weak

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SingleDatePicker } from 'react-dates';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
} from '@material-ui/core';

import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';

class DateSelectorWithNextAndPreviousDay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeDate(date) {
    const { changeSelectedDate, afterChange } = this.props;
    changeSelectedDate(date);
    afterChange();
  }

  render() {
    const {
      selectedDate, disabled, companyInfo, showTodayButton,
    } = this.props;

    const { state } = this;
    const isFocused = state.focused;
    const { dateLocale } = companyInfo;
    const today = moment();

    return (
      <>
        {showTodayButton && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.changeDate(today)}
            style={{ marginLeft: '20px' }}
            disabled={disabled || moment(selectedDate).isSame(today, 'day')}
          >
            Today
          </Button>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.changeDate(moment(selectedDate).subtract(1, 'days'))}
          style={{ margin: '0px 20px' }}
          disabled={disabled}
        >
          &lt;
        </Button>
        <SingleDatePicker
          date={moment(selectedDate)}
          onDateChange={(date) => this.changeDate(date)}
          focused={isFocused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          displayFormat={
            dateLocale && dateLocale === 'en-US'
              ? 'MMM DD, YYYY'
              : 'DD MMM YYYY'
          }
          numberOfMonths={1}
          isOutsideRange={() => false}
          fullWidth
          noBorder
          readOnly
          disabled={disabled}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.changeDate(moment(selectedDate).add(1, 'days'))}
          style={{ margin: '0px 20px' }}
          disabled={disabled}
        >
          &gt;
        </Button>
      </>
    );
  }
}

DateSelectorWithNextAndPreviousDay.propTypes = {
  changeSelectedDate: PropTypes.func.isRequired,
  afterChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.shape().isRequired,
  companyInfo: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  showTodayButton: PropTypes.bool,
};

DateSelectorWithNextAndPreviousDay.defaultProps = {
  disabled: false,
  showTodayButton: false,
};

function mapStateToProps(state) {
  const { session, companies } = state;
  const { selectedDate } = session;
  const { companyInfo } = companies;
  return {
    selectedDate,
    companyInfo,
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeSelectedDate: (date) => dispatch(sessionActions.changeSelectedDate(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateSelectorWithNextAndPreviousDay);
