/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider } from '@material-ui/core';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import DateRangePickerAndQuickDatesWrapper from '../../../components/Date/DateRangePickerAndQuickDatesWrapper';
import { updateOrdersStore, getOrders, getPaymentReport } from '../../../reducers/ordersReducer';
import { MultiSiteSelector } from './MultiSiteSelector';

export const PaymentsActionBar = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const calendar = useSelector((state) => state.calendar);
  const timezone = useSelector((state) => state.companies?.companyInfo?.timezone);
  const { selectedStartDate, selectedEndDate } = orders;
  const { sites } = calendar;

  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  // Set default to ALL_PAYMENTS and include site IDs when sites are available
  useEffect(() => {
    if (sites && sites.length > 0) {
      const defaultSelectedSiteIds = ['NON_SITE_SPECIFIC', 'ALL_SITES', ...sites.map((site) => site.id)];
      setSelectedSiteIds(defaultSelectedSiteIds);
    } else {
      setSelectedSiteIds(['NON_SITE_SPECIFIC', 'ALL_SITES']);
    }
  }, [sites]);

  useEffect(() => {
    if (initialLoad && selectedSiteIds.length > 0) {
      const startDate = selectedStartDate
        ? moment(selectedStartDate).tz(timezone).startOf('day')
        : moment().tz(timezone);
      const endDate = selectedEndDate
        ? moment(selectedEndDate).tz(timezone).endOf('day')
        : moment().tz(timezone);

      dispatch(getOrders({
        selectedSiteIds,
        startDate,
        endDate,
      }));

      dispatch(updateOrdersStore({
        selectedStartDate: startDate,
        selectedEndDate: endDate,
      }));

      setInitialLoad(false);
    }
  }, [initialLoad, selectedSiteIds, selectedStartDate, selectedEndDate, timezone, dispatch]);

  const handleDateChange = (options) => {
    const { startDate, endDate } = options;
    dispatch(
      updateOrdersStore({
        selectedStartDate: startDate ? moment(startDate).tz(timezone).startOf('day') : moment().tz(timezone),
        selectedEndDate: endDate ? moment(endDate).tz(timezone).endOf('day') : moment().tz(timezone),
      }),
    );
  };

  const handleSiteChange = (selectedIds) => {
    setSelectedSiteIds(selectedIds);
  };

  const handleSearchClick = () => {
    if (selectedSiteIds.length > 0 && selectedStartDate && selectedEndDate) {
      dispatch(getOrders({
        selectedSiteIds,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      }));
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '.25rem',
        padding: '1rem',
        backgroundColor: '#F5F5F5',
        border: '1px solid #ddd',
        borderRadius: '0.75rem',
        margin: '2rem 2rem 1rem 2rem',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DateRangePickerAndQuickDatesWrapper
          startDate={selectedStartDate !== null ? moment(selectedStartDate).tz(timezone).startOf('day') : moment().tz(timezone).startOf('month')}
          endDate={selectedEndDate !== null ? moment(selectedEndDate).tz(timezone).endOf('day') : moment().tz(timezone).endOf('day')}
          onDatesChange={handleDateChange}
          includeQuickOptions
        />
      </div>

      <Divider orientation="vertical" flexItem />

      <MultiSiteSelector
        sitesList={sites}
        selectedSiteIds={selectedSiteIds}
        handleSiteChange={handleSiteChange}
      />

      <div style={{ display: 'flex', alignSelf: 'stretch', gap: '1rem' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSearchClick}
        >
          Search
        </Button>
        <ConditionallyVisible
          condition={
            moment(selectedEndDate).isSame(selectedStartDate, 'day')
            && orders?.orders?.length > 0
          }
        >
          <Button
            id="manager-search-site-orders"
            onClick={() => dispatch(
              getPaymentReport({
                selectedSiteIds,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
              }),
            )}
            color="secondary"
            variant="contained"
          >
            Get Payment Report
          </Button>
        </ConditionallyVisible>
      </div>
    </div>
  );
};
